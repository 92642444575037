import request from "@/utils/request";
export class myRightApi {
    // 我的权益
    static myRight(params) {
        return request({
            url: "/my/legalRightCoupons",
            method: "get",
            params: params,
        });
    }
    // 我的权益详情
    static myRightDetail(params) {
        return request({
            url: "/my/legalRightCouponsDetail",
            method: "get",
            params: params,
        });
    }
    // 我的权益申请使用
    static applyUse(params) {
        return request({
            url: "/my/applyLegalRight",
            method: "post",
            data: params,
        });
    }
    //获取权益类型下拉列表数据
    static rightType(params) {
        return request({
            url: "/my/consultServiceSelectList",
            method: "get",
            params: params,
        });
    }
    //获取权益分类下拉列表
    static rightClassify(params) {
        return request({
            url: "/my/consultServiceCategorySelectList",
            method: "get",
            params: params,
        });
    }
    // 去付款
    static pay(params) {
        return request({
            url: "/my/pay",
            method: "get",
            params: params,
        });
    }
}