var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"content banxin"},[_c('div',{staticClass:"m16"},[_c('div',{staticClass:"left"},[_c('p',[_vm._v(_vm._s(_vm.userInfo.corpName)+" >")]),_c('h3',[_c('ww-open-data',{attrs:{"type":"userName","openid":_vm.userInfo.name,"corpid":_vm.userInfo.corp_id}})],1)]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":_vm.userInfo.avatar,"alt":""}})])]),_c('div',{staticClass:"warm m16",staticStyle:{"margin-top":"30px"}},[_vm._v(" 温馨提示：合作时请按照企业正规流程操作，请勿随意打款转账等。 ")])])]),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"Mtop banxin"},[_vm._m(0),_c('div',{staticClass:"m12",on:{"click":_vm.sao}},[_c('p',[_vm._v("扫码领取企业权益红包")]),_c('span',[_vm._v(">")])])]),_c('div',{staticClass:"Mmiddle banxin"},[_c('div',{staticClass:"m12",on:{"click":function () {
            this$1.$router.push({ path: '/myMarket/index' });
          }}},[_c('p',[_vm._v("我的市场")]),_c('span',[_vm._v(">")])]),_c('div',{staticClass:"m12",on:{"click":function () {
            this$1.$router.push({ path: '/myConsult/index' });
          }}},[_c('p',[_vm._v("我的咨询")]),_c('span',[_vm._v(">")])]),_c('div',{staticClass:"m12",on:{"click":function () {
            this$1.$router.push({ path: '/myRight/index' });
          }}},[_c('p',[_vm._v("我的权益")]),_c('span',[_vm._v(">")])])]),_c('div',{staticClass:"Mbottom banxin"},[_c('div',{staticClass:"m12",on:{"click":function () {
            this$1.$router.push({ path: '/helpCenter/index' });
          }}},[_c('p',[_vm._v("帮助中心")]),_c('span',[_vm._v(">")])]),_c('div',{staticClass:"m12",on:{"click":function () {
            this$1.$router.push({ path: '/feedBack/index' });
          }}},[_c('p',[_vm._v("意见反馈")]),_c('span',[_vm._v(">")])])]),_vm._m(1)]),_c('div',{staticStyle:{"padding-bottom":"20px","background-color":"#f2f3f7"}}),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m12"},[_c('p',[_vm._v("申请入驻")]),_c('span',[_vm._v(">")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"last"},[_c('p',[_vm._v("深圳市启晟教育科技有限公司")]),_c('p',[_vm._v("0755-1242434343")]),_c('span',[_vm._v("联系客服")])])}]

export { render, staticRenderFns }