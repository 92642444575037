<template>
  <div class="banxin">
    <div style="overflow: hidden">
      <h5>权益内容</h5>
      <p>{{rightDetailInfo.name}}</p>
      <!-- <div class="m16">过期时间：{{rightDetailInfo.expire_time}}</div> -->
      <h5>权益咨询服务</h5>
      <div class="m16" v-for="(item,index) in rightDetailInfo.consultService" :key="index">{{item.name}}：30分钟 &nbsp; &nbsp; &nbsp;{{item.times}}
      <div class="m16">过期时间：{{item.expire_time}}</div> 
      </div>
      <van-field
        style="border: 1px solid #ccc"
        v-model="password"
        name="密码"
        placeholder="请输入领取密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <button class="btn" @click="skip">提交</button>
    </div>
  </div>
</template>
  <script>
import { saoyisaoApi } from "../../api/saoyisao";

export default {
  data() {
    return {
      rightId: "",
      rightDetailInfo: {},
      password: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      console.log('权益详情页')
      let id=this.$route.query.id
      console.log('id',id)
      saoyisaoApi.saoMiao({id}).then(res=>{
        console.log('详情',res)
        this.rightDetailInfo=res.data
        this.rightId=res.data.id
      })
    },
    skip() {
     saoyisaoApi.receiveRight({id:this.rightId,password:this.password}).then(res=>{
      console.log(res)
      if(res.code==0){
        this.$toast(res.sMsg)
        this.$router.push({path:'/my/index'})
      }
      else{
        this.$toast(res.sMsg)
      }
     })
    },
  },
};
</script>
  <style lang="less" scoped>
h5 {
  margin-top: 12px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
}
p {
  height: 19px;
  font-size: 13px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  line-height: 19px;
}
.m16 {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  line-height: 25px;
}
.btn {
  margin-top: 30px;
  width: 100%;
  border: none;
  height: 42px;
  background: #3974c6;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
}
</style>