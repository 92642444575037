import request from "@/utils/request";
/**
 * 行业资讯
 */
export class hyInfoApi {
  // 获取行业资讯列表
  static hyInfoList(params) {
    return request({
      url: "/information/record",
      method: "get",
      params: params,
    });
  }
  // 获取行业资讯详情
  static hyInfoDetail(params) {
    return request({
      url: "/information/detail",
      method: "get",
      params: params,
    });
  }
}
