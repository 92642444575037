import request from "@/utils/request";
/**
 * 扫一扫获取权益详情
 */
export class saoyisaoApi {
    // 扫一扫
    static saoMiao(params) {
        return request({
            url: "/my/legalRightDetail",
            method: "get",
            params: params,
        });
    }
    //填写密码领取权益
    static receiveRight(params) {
        return request({
            url: "/my/receiveLegalRight",
            method: "post",
            data: params,
        });
    }
}
