<template>
  <div class="payPopu">
    <div class="top">
      <img src="../../img/consult/pay.png" alt="" />
    </div>
    <div class="order">支付遇到问题？</div>
    <div class="last">
      <button class="left" @click="back">重新支付</button>
      <button class="left" @click="() => this.$router.push('/myConsult/index')">
        我已支付
      </button>
    </div>
  </div>
</template>
<script>
import { myConsultApi } from "../../api/myConsult";
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      let id = this.$route.query.id;
      let url = encodeURIComponent(
        `${location.protocol + "//" + location.host}/payPopu/index?id=${id}`
      );
      myConsultApi.goPay({ id, device: "m" }).then((res) => {
        if (res.code == 0) {
          location.href = res.data.wechctUrl + `&redirect_url=${url}`;
        } else {
          this.$toast(res.sMsg);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.payPopu {
  overflow: hidden;
  width: 82%;
  margin: 0 auto;
}
.top {
  width: 100%;
  margin-top: 50px;
}
.order {
    margin: 20px;
  text-align: center;
  height: 28px;
  font-size: 20px;
  color: #302c48;
}
button {
    margin: 15px;
  border: none;
  width: 90%;
  height: 42px;
  background: #3974c6;
  border-radius: 21px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 42px;
}
</style>

