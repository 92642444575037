<template>
  <div class="consult">
    <div class="banxin">
      <!-- 简介部分 -->
      <div class="top">
        <div class="touxiang">
          <div class="t">
            <div class="headPortrait">
              <img :src="detailInfo.avatar" alt="" />
            </div>
          </div>
        </div>
        <div class="mk">
          <div class="name">
            <p>{{ detailInfo.name }} {{ detailInfo.position }}</p>
            <div class="left">
              &nbsp;&nbsp;<van-rate :count="starValue" v-model="starValue" />
            </div>
          </div>
          <div class="jianjie">
            {{ detailInfo.personal_profile }}
          </div>
          <div class="tag">
            <div
              class="box"
              v-for="(item, index) in detailInfo.tags"
              :key="index"
            >
              {{ item }}
            </div>
            <!-- <div class="box">标签标签</div> -->
          </div>
        </div>
      </div>
      <!-- 证书部分 -->
      <div class="cert">
        <p>资质证书</p>
        <div
          class="c"
          v-for="(item, index) in detailInfo.certificates"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <!-- 咨询计费部分 -->
      <div class="charge">
        <div class="cT">
          <div class="sp">咨询计费</div>
          <div class="xsp">咨询次数：{{ detailInfo.times }}</div>
        </div>
        <div class="content">
          <table>
            <tr v-for="(item, index) in detailInfo.relation" :key="index">
              <td class="firCloumn">{{ item.consultServiceName }}</td>
              <td class="sec">{{ item.consultServiceCategoryName }}</td>
              <td class="sec">{{ item.cost }}/30分钟</td>
            </tr>
            <!-- <tr>
              <td class="firCloumn">财税咨询</td>
              <td class="sec">分类2</td>
              <td class="sec">分类1</td>
            </tr> -->
          </table>
        </div>
      </div>
      <!-- <div class="last">
        <van-button
          class="btn"
          type="default"
          @click="() => this.$router.go(-1)"
          >返回</van-button
        >
        <van-button class="btn" type="info">选中</van-button>
      </div> -->
    </div>
  </div>
</template>
<script>
import { consultApi } from "../../api/consult";
export default {
  data() {
    return {
      detailInfo: {},
      starValue: 0,
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id;
      consultApi.memberDetail({ id }).then((res) => {
        console.log("成员详情", res);
        this.detailInfo = res.data;
        this.starValue = res.data.star_level;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.consult {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    #3975c6 0%,
    #f2f8ff 68%,
    rgba(250, 252, 255, 0) 100%
  );
}
.banxin {
  overflow: hidden;
}
// 简介部分
.top {
  position: relative;
  margin-top: 10px;
  height: 192px;
  .touxiang {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    .t {
      margin: 4px;
      width: 64px;
      height: 64px;
      .headPortrait,
      img {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        // border: 1px solid #979797;
        z-index: 99;
      }
    }
  }
}
.mk {
  width: 100%;
  // position: absolute;
  margin-top: -32px;
  top: 40px;
  height: 152px;
  background-color: #fff;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  .name {
    display: flex;
  }
  p {
    margin-top: 10px;
    margin-left: 92px;
    width: 160px;
    height: 21px;
    font-size: 15px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #4a4a4a;
    line-height: 21px;
  }
  .left {
    height: 14px;
    margin-top: 10px;
    font-size: 12px;
  }
  .jianjie {
    margin: 0 auto;
    width: 92%;
    height: 44px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 18px;
  }
  .tag {
    display: flex;
    margin-top: 10px;
    width: 92%;
    margin: 0 auto;
    .box {
      margin-right: 4px;
      padding: 6px 17px;
      background: #f2f8ff;
      border-radius: 13px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #4a4a4a;
    }
  }
}
.cert {
  min-height: 76px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
  p {
    margin: 0;
    padding-left: 15px;
    width: 100%;
    height: 41px;
    font-size: 15px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #4a4a4a;
    line-height: 41px;
    border-bottom: 1px solid #e6e6e6;
  }
  .c {
    margin-left: 15px;
    height: 36px;
    line-height: 36px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #4a4a4a;
  }
}
// 咨询计费部分
.charge {
  margin-top: 10px;
  width: 100%;
  height: 222px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  .cT {
    padding-left: 15px;
    justify-content: space-between;
    width: 100%;
    height: 41px;
    line-height: 41px;
    display: flex;
    border: 1px solid #e6e6e6;
    .sp {
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #4a4a4a;
    }
    .xsp {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #4a4a4a;
      padding-right: 15px;
    }
  }
  .content {
    margin-top: 10px;
    width: 92%;
    min-height: 32px;
    margin: 0 auto;
    background: #f2f8ff;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #4a4a4a;
    table {
      margin-top: 10px;
      width: 100%;
      border-radius: 4px;
      text-align: center;
      .firCloumn {
        background-color: #e8f3ff;
      }
      tr {
        height: 32.2px;
      }
      td {
        width: 33.3%;
      }
      .sec {
        background-color: #f2f8ff;
      }
    }
  }
}
.last {
  width: 92%;
  display: flex;
  position: fixed;
  bottom: 5px;
  justify-content: space-between;
  .btn {
    width: 46%;
  }
}
</style>