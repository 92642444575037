import request from "@/utils/request";
/**
 * 我的
 */
export class myApi {
    // 获取我的信息
    static myInfo(params) {
        return request({
            url: "/my/info",
            method: "get",
            params: params,
        });
    }
    // 我的市场的商机
    static myChance(params) {
        return request({
            url: "/my/businessOpportunities",
            method: "get",
            params: params,
        });
    }
    // 我的市场/我的商机/商机详情
    static myChanceDetail(params) {
        return request({
            url: "/my/businessOpportunitiesDetail",
            method: "get",
            params: params,
        });
    }
        // 我的市场/我的商机/编辑商机
        static editChance(params) {
            return request({
                url: "/my/editBusinessOpportunities",
                method: "post",
                data: params,
            });
        }
    // 我的市场/我的商机/抢订列表
    static myGrapMessage(params) {
        return request({
            url: "/my/myCommentList",
            method: "get",
            params: params,
        });
    }
    //下架商机
    static closeChance(params) {
        return request({
            url: "/my/closeBusinessOpportunities",
            method: "post",
            data: params,
        });
    }
    // 下架商机/抢订企业列表
    static grapCompany(params) {
        return request({
            url: "/my/grabCorpList",
            method: "get",
            params: params,
        });
    }
    // 抢商机
    static myGrapList(params) {
        return request({
            url: "/my/grabBusinessOpportunities",
            method: "get",
            params: params,
        });
    }
    // 抢商机/商机详情
    static myGrapChanceDetail(params) {
        return request({
            url: "/my/grabBusinessOpportunitiesDetail",
            method: "get",
            params: params,
        });
    }
    // 抢商机/抢订列表
    static myGrabCommentList(params) {
        return request({
            url: "/my/grabCommentList",
            method: "get",
            params: params,
        });
    }
    // 意见反馈
    static feedBack(params) {
        return request({
            url: "/my/feedback",
            method: "post",
            data: params,
        });
    }
    // 帮助中心
    static helpCenter(params) {
        return request({
            url: "/my/help",
            method: "get",
            params: params,
        });
    }
    // 帮助中心详情
    static helpCenterDetail(params) {
        return request({
            url: "/my/helpDetail",
            method: "get",
            params: params,
        });
    }
    
}