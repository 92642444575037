<template>
  <div class="grapMessage">
    <div class="banxin">
      <h5>抢订需求</h5>
      <van-field
        style="background: #f9f9f9; height: 166px"
        v-model="message"
        autosize
        type="textarea"
        maxlength="200"
        placeholder="请输入留言"
        show-word-limit
      />
      <span
        >提交留言后，客户会依据留言添加您企业微信，请注意通讯录添加客户！</span
      >
      <div class="last">
        <button class="btn1" @click="()=>{this.$router.go(-1)}">取消</button>
        <button class="btn2" @click="handleGrap">抢商机</button>
      </div>
    </div>
  </div>
</template>
<script>
import { chanceApi } from "../../api/chance";
export default {
  data() {
    return {
      message: "",
    };
  },
  methods: {
    handleGrap() {
      let id1 = location.search.split("=");
      let id = id1[1];
      chanceApi
        .grapChance({ opportunities_id: id, comment: this.message })
        .then((res) => {
          if(res.code==0){
            this.$toast.success('已抢购')
      this.$router.push({path:'/market/index'})
          }
          else{
            this.$toast(res.sMsg)
          }
        });
      
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-field__word-limit {
  margin-top: 84px;
}
/deep/ .van-toast{
    width:40% !important
  }
.banxin {
  overflow: hidden;
}
h5 {
  margin-top: 13px;
  height: 19px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #323232;
  line-height: 19px;
}
span {
  display: inline-block;
  margin-top: 10px;
  height: 53px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #999999;
  line-height: 19px;
}
.last {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 8px;
  width: 94%;
  .btn1 {
    border: none;
    width: 47%;
    height: 42px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #c1c9d0;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #c1c9d0;
  }
  .btn2 {
    border: none;
    width: 47%;
    height: 42px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
