<template>
  <div class="addChance">
    <div class="banxin">
      <div class="top">
        发布商机后您企业联系信息将会隐藏，在我的-我的市场中查看抢商机的公司联系方式，可参考后进行联系。
      </div>
      <div class="need">
        <div class="needT">需求名称</div>
        <div class="needB">
          <van-field
            style="background-color: #f9f9f9; height: 40px; border-radius: 5px"
            v-model="name1"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请输入名称"
            show-word-limit
          />
        </div>
      </div>
      <div class="need">
        <div class="needT">需求概括</div>
        <van-field
          style="background-color: #f9f9f9; border-radius: 5px"
          v-model="generalize"
          rows="2"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入概括"
          show-word-limit
        />
      </div>
      <div class="need">
        <div class="needT">需求时间</div>
        <!-- <div class="needTb">
          <input class="leftTime" placeholder="请选择时间"></input>
          <input class="rightTime" placeholder="请选择时间"></input>
        </div> -->
        <div class="list-item">
          <div class="item-content">
            <div
              @click="$refs.starttime.$show()"
              style="flex: 1; text-align: center"
            >
              {{ sTime ? sTime : "开始时间" }}
            </div>
            -
            <div
              @click="$refs.endtime.$show()"
              style="flex: 1; text-align: center"
            >
              {{ endTime ? endTime : "结束时间" }}
            </div>
          </div>
        </div>
      </div>
      <div class="need">
        <div class="needT">区域要求</div>
        <div class="needTa">
          <multiselect
            v-model="value.name"
            :custom-label="nameWithLang"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="请选择区域"
            label="name"
            track-by="name"
            :preselect-first="true"
          >
            <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template> -->
          </multiselect>
        </div>
      </div>
      <div class="need">
        <div class="needT">初步预算</div>
        <div class="needTf">
          <input class="left" v-model="lowBarget">
            <span>万</span>
          </input>
          <input class="right" v-model="highBarget">
          <span>万</span>
          </input>
        </div>
      </div>
      <div style="font-size:14px;margin-top: 10px;" v-if="audit_status==0">审核未通过</div>
      <div style="font-size:14px;margin-top: 10px;" v-if="audit_status==1">待审核</div>
      <div style="font-size:14px;margin-top: 10px;" v-if="audit_status==2&&audit_status==0">已下架</div>
      <div style="font-size:14px">驳回原因/需求介绍：{{audit_remark?audit_remark:'无'}}</div>
      <div class="last" v-if="audit_status==1">
        <button class="btn1" @click="()=>this.$router.go(-1)">取 消</button>
        <button class="btn2" @click="submit">再次提交</button>
      </div>
    </div>
    <addTime ref="starttime" @change="choseStartTime" />
    <addTime ref="endtime" @change="choseEndTime" />
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { myApi } from "../../api/my";
import { qyChanceApi } from "../../api/qyChance";
import global from "../../components/global"; //引用模块进来
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      audit_status: "",
      status: "",
      id: "",
      name1: "",
      generalize: "",
      // 文字
      message: "",
      sTime: "",
      endTime: "",
      value1: 0,
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      lowBarget: "",
      highBarget: "",
      value: {},
      options: global.option,
    };
  },
  created() {
    this.getChanceDetail();
  },
  methods: {
    getChanceDetail() {
      this.id = this.$route.query.id;
      qyChanceApi.ChanceDetail({ id: this.id }).then((res) => {
        console.log("res", res);
        let data = res.data;
        this.name1 = data.name;
        this.generalize = data.generalize;
        this.sTime = data.start_time.slice(0, 10);
        this.endTime = data.end_time.slice(0, 10);
        this.value.nam = data.area;
        console.log("this.value.name", this.value.nam);
        //     var area = [];
        //     this.value.nam.map((item) => {
        //     area.push(item.name);
        //   });
        //   this.value.name=area
        this.lowBarget = data.budget_range_start.slice(0, -1);
        this.highBarget = data.budget_range_end.slice(0, -1);
        this.audit_remark = data.audit_remark;
        this.audit_status=data.audit_status;
        this.status=data.status
      });
    },
    choseStartTime(data) {
      console.log("返回的时间", data);
      this.sTime = data;
    },
    choseEndTime(data) {
      console.log("返回的时间", data);
      this.endTime = data;
    },
    nameWithLang({ name }) {
      return `${name}`;
    },
    submit() {
      var area = [];
      console.log("区域", this.value);
      this.value.name.map((item) => {
        area.push(item.name);
      });
      console.log("区域", area);
      myApi
        .editChance({
          id: this.id,
          name: this.name1,
          generalize: this.generalize,
          start_time: this.sTime,
          end_time: this.endTime,
          budget_range_start: this.lowBarget,
          budget_range_end: this.highBarget,
          area,
        })
        .then((res) => {
          console.log("res", res);
          if (res.code == 0) {
            this.$toast.success("提交成功");
            this.$router.push({ path: "/qyChance/index" });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
::-webkit-input-placeholder {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #666666;
  vertical-align: middle;
}
.banxin {
  overflow: hidden;
}
.top {
  margin-top: 10px;
  height: 37px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #666666;
  line-height: 19px;
  background-color: #f9f9f9;
}
.needT {
  height: 32px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;

  color: #323232;
  line-height: 32px;
}
.needTb {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  .leftTime {
    text-indent: 4px;
    border: none;
    width: 48%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
  .rightTime {
    text-indent: 4px;
    border: none;
    width: 48%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
}
.needTf {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  text-indent: 6px;
  font-size: 18px;
  span {
    display: inline-block;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #000000;
    line-height: 40px;
  }
  .left {
    text-indent: 8px;
    border: none;
    width: 45%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
  .right {
    text-indent: 8px;
    border: none;
    width: 45%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
}
.last {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 8px;
  width: 94%;
  .btn1 {
    border: none;
    width: 47%;
    height: 42px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #c1c9d0;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #c1c9d0;
  }
  .btn2 {
    border: none;
    width: 47%;
    height: 42px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
// 时间选择
.list-item {
  margin-bottom: 10px;
}
.item-content {
  width: 100%;
  /* background: red; */
  border: 1px solid #e5e6e7;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 7px;
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
</style>