import request from "@/utils/request";
/**
 * 企业
 */
export class companyApi {
  // 获取企业联系人列表
  static cropContacts(params) {
    return request({
      url: "/corp/contacts",
      method: "get",
      params: params,
    });
  }
  // 获取企业详情
  static companyDetail(params) {
    return request({
      url: "/corp/detail",
      method: "get",
      params: params,
    });
  }
  // 获取企业商机列表
  static corpChanceList(params) {
    return request({
      url: "/corp/businessOpportunities",
      method: "get",
      params: params,
    });
  }
  // 申请添加联系人
  static applyAdd(params) {
    return request({
      url: "/corp/addContact",
      method: "post",
      data: params,
    });
  }
  // 申请添加联系人详情
  static applyAddDetail(params) {
    return request({
      url: "/corp/contactDetail",
      method: "get",
      params: params,
    });
  }
  // 企业分类
  static corpClassify(params) {
    return request({
      url: "/corp/category",
      method: "get",
      params: params,
    });
  }
}