<template>
  <div class="findCustom">
    <div class="c">
      <!-- 企业联系人 -->
      <div class="source banxin">
        <div class="Stop">
          <div class="left">发现客户</div>
        </div>
        <div class="Smiddle">
          <div v-if="contactInfo.length > 0">
            <div
              class="Mcontent"
              v-for="(item, index) in contactInfo"
              :key="index"
            >
              <div style="display: flex; justify-content: space-between">
                <div class="Mleft">
                  <img :src="item.avatar" alt="" />
                  <span
                    >&nbsp;<ww-open-data
                      type="userName"
                      :openid="item.name"
                      :corpid="item.corp_id"
                  /></span>
                </div>
                <div class="Mright">
                  <button
                    ref="btn"
                    v-bind:disabled="dis"
                    @click="add(item.id, index)"
                  >
                    申请添加
                  </button>
                </div>
              </div>
              <div class="corpName">
                <img :src="item.corp_logo" alt="" v-if="item.corp_logo" />
                <span class="sp" v-else>{{ item.corp_name.slice(0, 1) }}</span>
                <em> {{ item.corp_name }}</em>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty description="空空如也~" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { findCustomApi } from "../../api/findCustom";
import { companyApi } from "../../api/company";
import { initAgentConfig } from "../../utils/wxCodeAuth";

export default {
  data() {
    return {
      value: "",
      contactInfo: [],
      dis: false,
    };
  },
  mounted() {
    this.getList();
    initAgentConfig();
  },
  methods: {
    getList() {
      let query = this.$route.query.search;
      findCustomApi.searchCustom({ queryWord: query }).then((res) => {
        console.log("搜索客户列表", res);
        this.contactInfo = res.data.data;
      });
    },
    add(id, index) {
      companyApi.applyAdd({ id }).then((res) => {
        console.log("添加", res);
        if (res.code == 1) {
          let butt = [];
          butt = this.$refs.btn;
          butt.map((item, i) => {
            if (index == i) {
              this.dis = true;
              item.style.backgroundColor = "#ccc";
            }
          });
          this.$toast.success("发送请求成功");
        } else {
          this.$toast.fail(res.sMsg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  height: 60px;
  display: flex;
  justify-content: space-between;
  .topRight {
    width: 100%;
    float: left;
    box-shadow: 0 0px 0px #ccc;
  }
  .search {
    width: 100%;
    height: 36px;
    background: #f9f9f9;
    border-radius: 20px;
    line-height: 20px;
    box-shadow: 0 0px 0px #ccc;
    margin-top: 13px;
  }
}
.c {
  height: 8px;
  background: #f2f4f6;
}
// 企业联系人
.source {
  .Stop {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3974c6;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
  }
  .Smiddle {
    overflow: hidden;
    min-height: 107px;
    border-radius: 4px;
    .Mcontent {
      margin: 10px auto;
      margin-bottom: 10px;
      // border-bottom: 1px solid #4A4A4A;
      width: 91%;

      .Mleft {
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 50%;
        }
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
        }
      }
      .Mright {
        button {
          border: none;
          width: 86px;
          height: 24px;
          background: #3974c6;
          border-radius: 12px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }
}
.corpName {
  img {
    width: 16px;
    height: 16px;
  }
  .sp {
    font-size: 12px;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    background-color: #3974c6;
    color: #fff;
  }
  span {
    height: 19px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #555555;
    line-height: 19px;
  }
  em {
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #555555;
  }
}
</style>