<template>
  <div class="search banxin">
    <!-- <div class="top">
      <van-search
        class="input"
        v-model="inputSearch"
        placeholder="搜索联系人"
      />
    </div> -->
    <!--选择成员 -->
    <div class="select">
      <!-- <van-checkbox-group
        v-model="result"
        v-for="(item, index) in list"
        :key="index"
        @change="selectMember"
        :max="1"
      >
        <div class="checkBox">
          <div @click.stop="skipDetail(item.id)">
            <img :src="item.avatar" alt="" />&nbsp;&nbsp;{{
              item.name
            }}&nbsp;&nbsp; {{ item.position }}
          </div>
          <van-checkbox :name="item.id" />
        </div>
      </van-checkbox-group> -->
      <van-radio-group
        v-model="result"
        v-for="(item, index) in list"
        :key="index"
        @change="selectMember"
        :max="1"
      >
        <div class="checkBox">
          <div @click.stop="skipDetail(item.id)">
            <img :src="item.avatar" alt="" />&nbsp;&nbsp;{{
              item.name
            }}&nbsp;&nbsp; {{ item.position }}
          </div>
          <van-radio :name="item.id" />
        </div>
      </van-radio-group>
    </div>
    <div class="last">
      <button
        class="btn1"
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        取消
      </button>
      <button class="btn2" @click="confirm">确定</button>
    </div>
  </div>
</template>
<script>
import { consultApi } from "../../api/consult";
export default {
  // props:['classifyId'],
  data() {
    return {
      inputSearch: "",
      list: [],
      result: '',
      queryName: "",
      id: 0,
      cost: "",
      query: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.id = this.$route.query.id;
      let categoryId = this.$route.query.categoryId;
      this.cost = this.$route.query.cost;
      this.query = this.$route.query;
      console.log(this.$route.query);
      consultApi.consultMember({ id: this.id, categoryId }).then((res) => {
        console.log("成员", res);
        this.list = res.data;
      });
    },
    skipDetail(id) {
      this.$router.push({ path: "/consultMemberDetail/index", query: { id } });
    },
    selectMember(val) {
      console.log("names", val);
      for (let i = 0; i < this.list.length; i++) {
        if (val === this.list[i].id) {
          // this.result = this.list[i].id;
          this.queryName = this.list[i].name;
        }
      }
    },
    confirm() {
      console.log({
        name: this.queryName,
        memberId: this.result,
        id: this.id,
        cost: this.cost,
      });
      this.query.memberId = this.result ? this.result : "";
      this.query.name = this.queryName;
      this.query.cost = this.cost;
      this.$router.push({
        path: "/consult/selectConsult",
        query: this.query,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.value-class {
  flex: none !important;
}
// 顶部部分
.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    margin-top: 10px;
    width: 24px;
    height: 18px;
  }
  .input {
    margin-top: 10px;
    border: none;
    background: #f9f9f9;
    width: 86%;
    height: 36px;
    border-radius: 20px;
    font-size: 12px;
  }
}
.none {
  width: 100%;
  text-align: center;
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid #d8d8d8;
  p {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #4a4a4a;
  }
}
.inter {
  height: 32px;
  line-height: 32px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #4a4a4a;
}
.Stop {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    height: 21px;
    padding-left: 8px;
    border-left: 2px solid #3da1fe;
    font-size: 15px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #000000;
    line-height: 21px;
  }
}
.select {
  width: 90%;
  margin: 0 auto;
}
.checkBox {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #555555;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.last {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 8px;
  width: 94%;
  .btn1 {
    border: none;
    width: 47%;
    height: 42px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #c1c9d0;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #c1c9d0;
  }
  .btn2 {
    border: none;
    width: 47%;
    height: 42px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>