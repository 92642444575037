<template>
  <div class="applyAdd">
    <div class="banxin">
      <div class="top">
        <h5>申请添加: <ww-open-data type="userName" :openid="employeeData.name" :corpid="employeeData.corp_id"/></h5>
        <!-- <img :src="employeeData.qr_code" alt="二维码" /> -->
        <div class="img" @click="showPop"><img :src="employeeData.qr_code" alt="二维码" /></div>
        <h5>长按扫一扫添加好友</h5>
        <van-popup
          v-model="showCode"
          closeable
          style="
            width: 260px;
            height: 250px;
            text-align: center;
            margin-left: 45%;
          "
        >
          <img
            style="
              margin-top: 30px;
              width: 200px;
              height: 200px;
              text-align: center;
            "
            :src="employeeData.qr_code"
            alt=""
          />
        </van-popup>
      </div>
      <!-- 企业信息 -->
      <div class="brand">
        <div class="Stop">
          <div class="left">企业信息</div>
        </div>
        <div class="Bmiddle">
          <div class="Bmcontent">
            <div><span>企业全称：{{corpData.name}}</span></div>
            <div style="line-height:18px"><span>企业地址：{{corpData.address}}</span></div>
            <div><span>行业类型：{{corpData.industry_category}}</span></div>
            <div><span>员工规模：{{corpData.corp_scale}}</span></div>
            <div>
              <span>企业官网：<a>{{corpData.official_website}}</a></span>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业品牌 -->
      <div class="brand">
        <div class="Stop">
          <div class="left">企业品牌</div>
        </div>
        <div class="Smiddle" v-for="(item,index) in brandData" :key="index">
          <!-- <div class="you" v-if="brandDetailInfo.recommend!=''">{{brandDetailInfo.recommend}}</div> -->
          <div class="left">
            <img :src="item.logo" alt="" v-if="item.logo" />
            <span v-else>{{ item.corp_name.slice(0, 1) }}</span>
          </div>
          <div class="right">
            <h1>{{item.name}}</h1>
            <p>{{item.introduction}}</p>
            <div class="bottom">
              <div class="bleft">
                <img :src="item.corp_logo" /><span>{{item.introduction}}</span>
              </div>
              <!-- <div class="p">100+人最近联系</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 企业联系人 -->
      <div class="contact">
        <div class="Stop">
          <div class="left">企业联系人</div>
        </div>
        <div class="Cmiddle">
          <div class="Cmcontent" v-for="(item, index) in contactData"
            :key="index">
            <div class="left" style="margin-top: -6px">
              <img :src="item.avatar" alt="" /><span
                >&nbsp;<ww-open-data type="userName" :openid="item.name" :corpid="item.corp_id"
              /></span>
            </div>
            <div class="right" style="margin-top: -6px">
              <button v-bind:disabled="dis"
                @click="add(item.id, index)">申请添加</button>
            </div>
          </div>
        </div>
      </div>
      <!--企业商机 -->
      <div class="chance">
        <div class="Ctop">
          <div class="left">企业商机</div>
          <!-- <div class="right">
              <button>本周内</button>
              <button style="border-left: ">本月内</button>
            </div> -->
        </div>
        <div v-if="companyChance.length>0">
        <div class="Cmiddle" v-for="(item, index) in companyChance"
          :key="index"
          @click.stop="skipChanceDetail(item.id)">
          <div>
            <div class="content">
            <div class="ctop">
              <h1>{{ item.name }}</h1>
              <p>
                {{ item.generalize }}
              </p>
            </div>
            <div class="cCenter">
              <div class="box">
                <div class="m17">
                  <span>初步预算:</span>
                  <i
                    >{{ item.budget_range_start }}-{{
                      item.budget_range_end
                    }}</i
                  >
                </div>
                <div class="m17">
                  <span>需求时间:</span>
                  <span
                    >{{ item.start_time.slice(0, 10) }} —
                    {{ item.end_time.slice(0, 10) }}</span
                  >
                </div>
                <div class="m17">
                  <span>区域要求:</span> <span>{{ item.area }}</span>
                </div>
                <div class="m17">
                  <img :src="item.corp_logo" alt="" />&nbsp;<em>{{
                    item.corp_name
                  }}</em>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="bl">
                <span>已抢：{{ item.grab_num }}人</span>
              </div>
              <button style="margin-top: -4px" @click.stop="skipGrap(item.id)">
                抢商机
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
          <van-empty description="暂无商机~" />
        </div>
    </div>
  </div>
  </div>
</template>
<script>
import { companyApi } from '../../api/company';
import { initAgentConfig } from "../../utils/wxCodeAuth";
import { brandApi } from "../../api/brand";

export default {
  data() {
    return {
      showCode: false,
      dis: false,
      employeeData:{},
      corpData:{},
      brandData:[],
      contactData:[],
      companyChance:[],
      value1: 0,
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
    };
  },
  created(){
    this.getData()
    initAgentConfig()
  },
  methods: {
    showPop(){
        this.showCode=true
    },
    getData(){
      let id1=location.search
      let id=id1.split('=')[1]
      companyApi.applyAddDetail({employeeId:id}).then(res=>{
        console.log('申请添加联系人详情',res)
        this.employeeData=res.data.employee
        this.corpData=res.data.corp
        this.brandData=res.data.brand.data
        this.contactData=res.data.contacts.data
        this.companyChance=res.data.businessOpportunities.data
      })
    },
    add(id, index) {
      companyApi.applyAdd({ id }).then((res) => {
        console.log("添加", res);
        if (res.code == 1) {
          let butt = [];
          butt = this.$refs.btn;
          butt.map((item, i) => {
            if (index == i) {
              this.dis = true;
              item.style.backgroundColor = "#ccc";
            }
          });
          this.$toast.success("发送请求成功");
        } else {
          this.$toast.fail(res.sMsg);
        }
      });
    },
    skipChanceDetail(id) {
      this.$router.push({ path: "/chanceDetail/index", query: { id: id } });
    },
    skipGrap(id) {
      this.$router.push({ path: "/grapMessage/index", query: { id } });
      return;
    },
  },
};
</script>
<style lang="less" scoped>
.applyAdd{
    height: auto;
  background: #fff;
}
.banxin {
  overflow: hidden;
  
}
.top {
  overflow: hidden;
  text-align: center;
  margin-top: 12px;
  height: 189px;
  background: #f2f8ff;
  border-radius: 5px;
  h5 {
    margin: 17px 0;
    height: 20px;
    font-size: 13px;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
  }
  img {
    width: 94px;
    height: 94px;
    object-fit: cover;
  }
  .img{
    width: 98px;
    height: 98px;
    line-height: 98px;
    background-color: #fff;
    margin: 0 auto;

  }
}
.Stop {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    height: 21px;
    padding-left: 8px;
    border-left: 2px solid #3da1fe;
    font-size: 15px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #000000;
    line-height: 21px;
  }
}
// 企业信息部分
.Bmiddle {
  min-height: 129px;
  background: #f2f8ff;
  border-radius: 4px;
  .Bmcontent {
    div {
      min-height: 24px;
      line-height: 24px;
    }
    margin-left: 10px;
    span {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
    }
  }
}
// 企业品牌部分
.brand {
  .Smiddle {
    position: relative;
      .you {
    position: absolute;
    top: 10px;
    right: 0;
    text-align: center;
    color: #fff;
    font-size: 12px;
    width: 59px;
    height: 18px;
    background: #f5a623;
    border-radius: 50px 0px 0px 50px;
  }
    // width: 93%;
    min-height: 118px;
    background: #f2f8ff;
    border-radius: 5px;
    .left {
      float: left;
      width: 40px;
      margin: 14px 4px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      span {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      background-color: #3974c6;
      text-align: center;
    }
    }
    .right {
      float: left;
      // width: 296px;
      width: 84%;
      margin-top: 14px;
      h1 {
        height: 21px;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #4a4a4a;
        line-height: 21px;
      }
      p {
        // width: 286px;
        width: 100%;
        height: 37px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8f8f8f;
        line-height: 19px;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        height: 20px;
        line-height: 20px;
        .bleft {
          margin-top: -4px;
          height: 20px;
          line-height: 20px;
          span {
            display: inline-block;
            margin-top: -4px;
            height: 20px;
            line-height: 20px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
        .p {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8f8f8f;
        }
      }
    }
  }
}

// 企业联系人
.contact {
  .Cmiddle {
    min-height: 62px;
    background: #f2f8ff;
    border-radius: 4px;
  }
  .Cmcontent {
    width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 62px;
    align-items: center;
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    span {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #555555;
    }
    button {
      border: none;
      width: 86px;
      height: 24px;
      background: #3974c6;
      border-radius: 12px;
      font-size: 13px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
// 发布的商机
.chance {
  min-height: 232px;
  .Ctop {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3974c6;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
    .right {
      button {
        width: 54px;
        height: 20px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #3974c7;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3974c6;
        line-height: 15px;
      }
    }
  }
  .Cmiddle {
    width: 100%;
    min-height: 232px;
    background: #f9f9f9;
    border-radius: 5px;
    overflow: hidden;
    .content {
      margin: 9px auto;
      width: 94%;
      .ctop {
        width: 100%;
        min-height: 70px;
        h1 {
          width: 100%;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          width: 100%;
          min-height: 48px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 19px;
        }
      }
      .cCenter {
        width: 100%;
        height: 107px;
        background: #ffffff;
        border-radius: 5px;
        .box {
          width: 97%;
          margin: 8px auto;
          .m17 {
            height: 20px;
          }
          span {
            height: 19px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
          }
          i {
            height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #e84a10;
            line-height: 20px;
          }
          em {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 28px;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      margin-top: 8px;
      .bl {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e84a10;
        line-height: 20px;
      }
      button {
        border: none;
        font-size: 14px;
        color: #fff;
        width: 86px;
        height: 24px;
        line-height: 20px;
        background: #f5a623;
        border-radius: 12px;
      }
    }
  }
}
</style>