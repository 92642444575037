<template>
  <div class="market">
    <div class="banxin">
      <div class="top">
        <img src="../../img/market/jingshi.png" alt="" />
        <van-search
          class="input"
          v-model="inputSearch"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        />
        <img src="../../img/market/add.png" alt="" @click="skipAdd" />
      </div>
      <div class="Mtop">
        <div>
          <p>共 {{ needLength }} 个需求</p>
        </div>
        <div><img style="opacity: 0;" /></div>
      </div>
      <div class="chance" v-if="chanceList.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <chanceStyle :list="chanceList" />
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { qyChanceApi } from "../../api/qyChance";
import { initAgentConfig } from "../../utils/wxCodeAuth";

export default {
  data() {
    return {
      inputSearch: "",
      needLength: 0,
      chanceList: [],
      inputSearch: "",
      loading: false,
      finished: false,
      total: 0,
      page: 1,
    };
  },
  created() {
    initAgentConfig();
    setTimeout(() => {
      this.getChanceList();
    },300)
    // sessionStorage.setItem("active", 1);
  },
  methods: {
    onSearch(){
      let query =this.inputSearch
      this.chanceList=[]
      this.getChanceList(query);
    },
    skipAdd() {
      this.$router.push({ path: "/addChance/index", query: { skip: "qy" } });
    },
    getChanceList(queryWord) {
      qyChanceApi.chanceList({ page: this.page,queryWord }).then((res) => {
        console.log("商机列表", res);
        this.needLength = res.data.data.length;
        this.chanceList.push(...res.data.data); // 将数据放入list中
        console.log("this.chanceList", this.chanceList);
        this.loading = false;
        if (this.chanceList.length >= res.data.total) {
          this.finished = true; // 结束加载状态
        }
      });
    },
    // 被 @load调用的方法
    onLoad() {
      // 若加载条到了底部
      this.getChanceList(); // 调用上面方法,请求数据
      this.page++; // 分页数加一
      this.finished = false;
    },
  },
};
</script>
<style lang="less" scoped>
.market {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
// 顶部部分
.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    margin-top: 10px;
    width: 20px;
    height: 20px;
  }
  .input {
    margin-top: 10px;
    border: none;
    background: #f9f9f9;
    width: 83%;
    height: 36px;
    border-radius: 20px;
    font-size: 12px;
  }
}
.Mtop {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-bottom: -6px;
  }
  img {
    width: 12px;
    height: 12px;
  }
}
</style>