<template>
  <div class="addChance" style="height:100%">
    <div class="banxin">
      <div class="top">
        发布商机后您企业联系信息将会隐藏，在我的-我的市场中查看抢商机的公司联系方式，可参考后进行联系。
      </div>
      <div class="need">
        <div class="needT">需求名称</div>
        <div class="needB">
          <van-field
            style="background-color: #f9f9f9; height: 40px; border-radius: 5px"
            v-model="name1"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="请输入名称"
            show-word-limit
          />
        </div>
      </div>
      <div class="need">
        <div class="needT">需求概括</div>
        <van-field
          style="background-color: #f9f9f9; border-radius: 5px"
          v-model="generalize"
          rows="2"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入概括"
          show-word-limit
        />
      </div>
      <div class="need">
        <div class="needT">初步预算 (只保留一位小数)</div>
        <div class="needTf">
          <input class="left" style="text-indent: 8px;" v-model="lowBarget">
            <span>万</span>
          </input>
          <input style="text-indent: 8px;" class="right" v-model="highBarget">
          <span>万</span>
          </input>
        </div>
      </div>
      <div class="need">
        <div class="needT">区域要求</div>
        <div class="needTa">
          <!-- :options-limit="300"–将显示的选项限制为20。用于优化目的。 -->
          <multiselect
            v-model="value.name"          
            :custom-label="nameWithLang"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="请选择区域"
            label="name"
            track-by="name"
            :preselect-first="true"
          >
          </multiselect>
        </div>
      </div>
      <div class="need">
        <div class="needT">需求时间</div>
        <div class="list-item">
          <div class="item-content">
            <div
              @click="$refs.starttime.$show()"
              style="flex: 1; text-align: center"
            >
              {{ sTime ? sTime : "开始时间" }}
            </div>
            -
            <div
              @click="$refs.endtime.$show()"
              style="flex: 1; text-align: center"
            >
              {{ endTime ? endTime : "结束时间" }}
            </div>
          </div>
        </div>
      </div>
      
      
      <div class="last">
        <button class="btn1" @click="()=>this.$router.go(-1)">取 消</button>   
        <button class="btn2" @click="release">发 布</button>
      </div>
    </div>
    <addTime ref="starttime" @change="choseStartTime" />
    <addTime ref="endtime" @change="choseEndTime" />
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { chanceApi } from "../../api/chance";
import global from "../../components/global"; //引用模块进来
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      urlParam: "",
      name1: "",
      generalize: "",
      // 文字
      message: "",
      sTime: "",
      endTime: "",
      value1: 0,
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      lowBarget: "",
      highBarget: "",
      value: {},
      options: global.option,
    };
  },
  created() {
    this.getUrlParam();
  },
  methods: {
    getUrlParam() {
      this.urlParam = this.$route.query;
      console.log(this.urlParam);
    },
    choseStartTime(data) {
      console.log("返回的时间", data);
      this.sTime = data;
    },
    choseEndTime(data) {
      console.log("返回的时间", data);
      this.endTime = data;
    },
    nameWithLang({ name }) {
      return `${name}`;
    },
    release() {
      var area = [];
      console.log("区域", this.value);
      this.value.name.map((item) => {
        area.push(item.name);
      });
      console.log("区域", area);
      chanceApi
        .addChance({
          name: this.name1,
          generalize: this.generalize,
          start_time: this.sTime,
          end_time: this.endTime,
          budget_range_start: this.lowBarget,
          budget_range_end: this.highBarget,
          area,
        })
        .then((res) => {
          console.log("res", res);
          if (res.code == 0) {
            this.$toast.success("发布成功");
            this.$router.push({ path: "/myMarket/index" });
          } else {
            this.$toast(res.sMsg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
::-webkit-input-placeholder {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #666666;
  vertical-align: middle;
}
.banxin {
  height: 100%;
  overflow: hidden;
}
.top {
  margin-top: 10px;
  height: 37px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #666666;
  line-height: 19px;
  background-color: #f9f9f9;
}
.needT {
  height: 32px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;

  color: #323232;
  line-height: 32px;
}
.needTb {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  .leftTime {
    text-indent: 4px;
    border: none;
    width: 48%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
  .rightTime {
    text-indent: 4px;
    border: none;
    width: 48%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
}
.needTf {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  text-indent: 6px;
  font-size: 18px;
  span {
    display: inline-block;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #000000;
    line-height: 40px;
  }
  .left {
    border: none;
    width: 48%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
  .right {
    border: none;
    width: 48%;
    height: 40px;
    background: #f9f9f9;
    border-radius: 5px;
  }
}
.last {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 18px;
  width: 94%;
  .btn1 {
    border: none;
    width: 47%;
    height: 42px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #c1c9d0;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #c1c9d0;
  }
  .btn2 {
    border: none;
    width: 47%;
    height: 42px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
// 时间选择
.list-item {
  margin-bottom: 10px;
}
.item-content {
  width: 100%;
  /* background: red; */
  border: 1px solid #e5e6e7;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 7px;
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
</style>