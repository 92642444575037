import request from "@/utils/request";
export class consultApi {
    // 咨询首页
    static consultHome(params) {
        return request({
            url: "/consultService/homePage",
            method: "get",
            params: params,
        });
    }
    // 咨询分类
    static consultClassify(params) {
        return request({
            url: "/consultService/category",
            method: "get",
            params: params,
        });
    }
    //咨询成员
    static consultMember(params) {
        return request({
            url: "/consultService/member",
            method: "get",
            params: params,
        });
    }
    //成员详情
    static memberDetail(params) {
        return request({
            url: "/consultService/memberDetail",
            method: "get",
            params: params,
        });
    }
    //获取成员时间段
    static memberDate(params) {
        return request({
            url: "/consultService/timeSlot",
            method: "get",
            params: params,
        });
    }
    //创建订单
    static creatOrder(params) {
        return request({
            url: "/consultService/createOrder",
            method: "post",
            data: params,
        });
    }
    //查询订单状态
    static queryStatus(params) {
        return request({
            url: "/consultService/orderStatus",
            method: "get",
            params: params,
        });
    }
}