import request from "@/utils/request";
/**
 * 发现客户
 */
export class findCustomApi {
  // 发现客户列表
  static customList(params) {
    return request({
      url: "/discoverCustomer/homePage",
      method: "get",
      params: params,
    });
  }
  // 搜索客户
  static searchCustom(params) {
    return request({
      url: "/discoverCustomer/search",
      method: "get",
      params: params,
    });
  }
}
