import request from "@/utils/request";
/**
 * 商机
 */
export class chanceApi {
  // 获取商机列表
  static chanceList(params) {
    return request({
      url: "/corpBusinessOpportunities/record",
      method: "get",
      params: params,
    });
  }
   // 获取商机详情
   static chanceDetail(params) {
    return request({
      url: "/corpBusinessOpportunities/detail",
      method: "get",
      params: params,
    });
  }
    // 发布商机
    static addChance(params) {
      return request({
        url: "/corpBusinessOpportunities/publish",
        method: "post",
        data: params,
      });
    }
    // 抢商机
    static grapChance(params) {
      return request({
        url: "/corpBusinessOpportunities/grab",
        method: "post",
        data: params,
      });
    }
       // 获取感兴趣的商机
   static interestList(params) {
    return request({
      url: "/corpBusinessOpportunities/interest",
      method: "get",
      params: params,
    });
  }
}
