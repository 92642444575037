import request from "@/utils/request";
/**
 * banner图
 */
export class indexApi {
  // 获取首页banner图
  static homepage(params) {
    return request({
      url: "/banner/homePage",
      method: "get",
      params: params,
    });
  }
  //首页推荐品牌
  static recommendBrand(params) {
    return request({
      url: "/corpBrand/recommend",
      method: "get",
      params: params,
    });
  }

}
