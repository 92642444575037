<template>
  <div class="myMarket" style="min-height: 100%; background: #fff;overflow: auto;">
    <div class="top banxin">
      <img src="../../img/market/jingshi.png" alt="" />
      <van-search
        class="input"
        v-model="inputSearch"
        placeholder="请输入搜索关键词"
        @search="onSearch"
      />
      <img
        src="../../img/market/add.png"
        alt=""
        @click="
          () => {
            this.$router.push('/addChance/index');
          }
        "
      />
    </div>
    <div class="tab">
      <van-tabs v-model="active" title-active-color="#3974C6" color="#3974C6">
        <van-tab title="我的商机">
          <div class="banxin" style="margin-top: -12px">
            <div class="middle">
              <div class="Mtop">
                <div>
                  <p>共 {{ chanceList.length }} 个需求</p>
                </div>
                <div><img style="opacity: 0;" /></div>
              </div>
              <!-- 发布的商机 -->
              <div class="chance" v-if="chanceList.length > 0">
                <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                >
                  <div
                    class="Cmiddle"
                    v-for="(item, index) in chanceList"
                    :key="index"
                    @click.stop="
                      skipMyChanceDetail(
                        item.id,
                        item.audit_status,
                        item.status
                      )
                    "
                  >
                    <div class="content">
                      <div class="ctop">
                        <h1>
                          {{ item.name }}
                        </h1>
                        <p>
                          {{ item.generalize }}
                        </p>
                      </div>
                      <div class="cCenter">
                        <div class="box">
                          <div class="m17" style="margin-top:5px">
                            <span>初步预算:</span>
                            <i
                              >{{ item.budget_range_start }} -
                              {{ item.budget_range_end }}</i
                            >
                          </div>
                          <div class="m17">
                            <span>需求时间:</span>
                            <span
                              >{{ item.start_time.slice(0, 16) }} -
                              {{ item.end_time.slice(0, 16) }}</span
                            >
                          </div>
                          <div class="m17">
                            <span>区域要求:</span> <span>{{ item.area }}</span>
                          </div>
                          <div class="m17">
                            <em>{{ item.create_time.slice(0, 16) }} &nbsp;</em>
                          </div>
                        </div>
                      </div>

                      <div
                        class="bottom"
                        v-if="item.audit_status == 2 && item.status == 1"
                      >
                        <div class="bl">
                          <span>已抢：{{ item.grab_num }}人</span>
                        </div>
                        <button
                          style="margin-top: -4px"
                          @click="offChance(item.id)"
                        >
                          下架商机
                        </button>
                      </div>
                      <div
                        class="bottom"
                        v-else-if="item.audit_status == 2 && item.status == 0"
                      >
                        <div class="bl"></div>
                        <button style="margin-top: -4px">已下架</button>
                      </div>
                      <div
                        class="bottom"
                        style="justify-content: flex-end"
                        v-else-if="item.audit_status == 1"
                      >
                        <button style="margin-top: -4px">待审核</button>
                      </div>
                      <div class="bottom" v-else>
                        <div class="bl"><span>审核未通过</span></div>
                        <button style="margin-top: -4px">重新编辑</button>
                      </div>
                    </div>
                  </div>
                </van-list>
              </div>
              <div v-else>
                <van-empty description="暂无商机" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="抢商机"><myGrapChance /></van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { myApi } from "../../api/my";
import myGrapChance from "./myGrapChance.vue";
export default {
  components: { myGrapChance },
  data() {
    return {
      active: 0,
      chanceList: [],
      inputSearch: "",
      loading: false,
      finished: false,
      total: 0,
      page: 1,
    };
  },
  created() {
    this.getMyChance("");
  },
  methods: {
    onSearch() {
      let query = this.inputSearch;
      this.chanceList = [];
      this.getMyChance(query);
    },
    getMyChance(queryWord) {
      myApi.myChance({ page: this.page, queryWord }).then((res) => {
        console.log("我的商机列表", res);
        this.chanceList.push(...res.data.data); // 将数据放入list中
        console.log("this.chanceList", this.chanceList);
        this.loading = false;
        if (this.chanceList.length >= res.data.total) {
          this.finished = true; // 结束加载状态
        }
      });
    },
    // 被 @load调用的方法
    onLoad() {
      // 若加载条到了底部
      this.page++; // 分页数加一
      this.getMyChance(); // 调用上面方法,请求数据
      this.finished = false;
    },
    skipMyChanceDetail(id, audit_status, status) {
      console.log("id audit_status", audit_status);
      if (audit_status == 2 && status == 1) {
        this.$router.push({ path: "/myChanceDetail/index", query: { id } });
      } else {
        this.$router.push({ path: "/failedChanceDetail/index", query: { id } });
      }
    },
    offChance(id) {},
  },
};
</script>
<style lang="less" scoped>
  /deep/ .van-search__content {
  padding-left: 0;
}
// 顶部部分
.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    margin-top: 10px;
    width: 20px;
    height: 20px;
  }
  
  .input {
    margin-top: 10px;
    border: none;
    background: #f9f9f9;
    width: 83%;
    height: 36px;
    border-radius: 20px;
    font-size: 12px;
  }
}
.tab {
  margin-top: 12px;
  width: 100%;
  height: 42px;
  border: 1px solid #f1f1f1;
}
// 内容部分
.middle {
  flex: 1;
  overflow: auto;
  .Mtop {
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-bottom: -6px;
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
  // 发布的商机
  .Cmiddle {
    margin-top: 10px;
    min-height: 200px;
    background: #f9f9f9;
    border-radius: 5px;
    overflow: hidden;
    .content {
      margin: 9px auto;
      width: 90%;
      .ctop {
        width: 100%;
        min-height: 50px;
        h1 {
          // width: 315px;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          // width: 334px;
          width: 100%;
          min-height: 20px;
          line-height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #555555;
          line-height: 19px;
        }
      }
      .cCenter {
        // width: 323px;
        width: 100%;
        min-height: 107px;
        background: #ffffff;
        border-radius: 5px;
        .box {
          // width: 313px;
          width: 96%;
          margin: 8px auto;
          overflow: hidden;
          .m17 {
            min-height: 20px;
            line-height: 18px;
            img {
              width: 16px;
              height: 16px;
            }
            em {
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #999999;
              line-height: 18px;
            }
          }
          span {
            height: 19px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
          }
          i {
            height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #e84a10;
            line-height: 20px;
          }
          em {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 28px;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      margin-top: 8px;
      .bl {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e84a10;
        line-height: 20px;
      }
      button {
        border: none;
        font-size: 14px;
        color: #fff;
        width: 86px;
        height: 24px;
        line-height: 20px;
        background: #f5a623;
        border-radius: 12px;
      }
    }
  }

  i,
  em,
  b {
    font-style: normal;
  }
}
</style>