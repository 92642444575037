<template>
  <div class="consult">
    <div class="main banxin">
      <div v-if="consultHomeInfo.length > 0">
        <div class="m16" v-for="(item, index) in consultHomeInfo" :key="index">
          <div class="bg" :style="{background:item.background_color}">
          <div class="circle"></div>

          </div>
          <div class="content" @click.stop="skip(item.id, item.cost)">
            <div class="left">
              <h2>{{ item.name }}</h2>
              <span>{{ item.sub_title }}</span>
            </div>
            <div class="right">
              <img :src="item.image" alt="" />
            </div>
            <div
              @click.stop="getDetail(item.brief_introduction, index)"
              class="icon"
            >
              <van-icon :name="num === index ? 'arrow-down' : 'arrow-up'" />
            </div>
          </div>
          <div class="detail" v-show="showDetail[index]">
            {{ detail }}
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty description="空空如也~" />
      </div>
      <div style="padding-bottom: 50px"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { consultApi } from "../../api/consult";
export default {
  data() {
    return {
      showDetail: [],
      consultHomeInfo: [],
      detail: "",
      index2: "",
      down: "arrow-up",
      cost: 0,
      num: ''
    };
  },
  created() {
    sessionStorage.setItem("active", 2);
    this.getConsultHome();
  },
  methods: {
    getDetail(detail, index) {
      // console.log('index',index)
      let showDetail = !this.showDetail[index]
      this.showDetail = this.showDetail.map(item=>item = false);
      this.showDetail[index] = showDetail
      if(showDetail) this.num = index
      else this.num = ''
      this.detail = detail;
      // this.index2 = index;
      console.log('this.index2',this.index2)
      if (this.down == "arrow-down") {
        this.down = "arrow-up";
      } else {
        this.down = "arrow-down";
      }
    },
    getConsultHome() {
      consultApi.consultHome().then((res) => {
        console.log("咨询首页", res);
        this.consultHomeInfo = res.data;
        res.data.map(item=>{this.showDetail.push(false)})
        console.log(this.showDetail);
      });
    },
    skip(id, cost) {
      this.$router.push({
        path: "/consult/selectConsult",
        query: { id, cost },
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .bg{
    overflow: hidden;
    position: absolute;
    width:43%;
    height: 125px;
    right:10px;
    opacity: 0.2;
  }
  .circle{
    right:55%;
    position: absolute;
    width: 136%;
    height: 260px;
    top:-125px;
    border-radius: 50%;
    z-index: 1;
    background: #fff;
  }
.consult {
  position: relative;
  min-height: 700px;
  width: 100%;
  background-color: #f9f9f9;
}
.m16 {
  overflow: hidden;
  margin-top: 16px;
  min-height: 102px;
  background: #ffffff;
  border-radius: 10px;
  .content {
    z-index: 2;
    overflow: hidden;
    position: relative;
    display: flex;
    width: 85%;
    min-height: 44px;
    margin: 0 auto;
    margin-top: 28px;
    justify-content: space-between;
    .icon {
      position: absolute;
      bottom: 5px;
      left: 48%;
      color: #ccc;
    }
    .left {
      display: flex;
      flex-direction: column;
      width: 127px;
      height: 40px;
      h2 {
        width: 200px;
        margin: 0;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
      }
      span {
        width: 200px;
        display: inline-block;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #9c9c9c;
        margin-top: 8px;
      }
    }
    .right {
      // width: 40%;
      height: 102px;
      margin-left: 90px;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
  .detail {
    width: 85%;
    min-height: 44px;
    line-height: 19px;
    margin: 0 auto;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #888888;
  }
}
</style>