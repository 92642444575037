<template>
    <div>
        <comChanceDetail :myChanceDetailInfo="myChanceDetailInfo" :myGrapListInfo="myGrapListInfo" />
    </div>
</template>
<script>
import { myApi } from '../../api/my';
export default {
  data() {
    return {
      chanceId: "",
      myChanceDetailInfo: "",
      myGrapListInfo: [],
    };
  },
  created() {
    this.getMyGrapChanceDetail();
    this.getGrapListInfo()
  },
  methods: {
    getMyGrapChanceDetail() {
      let id1 = location.search;
      this.chanceId = id1.split("=")[1];
      myApi.myGrapChanceDetail({ id: this.chanceId }).then((res) => {
        console.log("我抢的商机详情", res);
        this.myChanceDetailInfo = res.data;
      });
    },
    // 抢订列表信息
    getGrapListInfo() {
      myApi.myGrabCommentList({ id: this.chanceId }).then((res) => {
        console.log("抢商机抢订列表", res);
        this.myGrapListInfo = res.data.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>