
<script>
import { loginApi } from "../../api/login";
import { setCookie, base64Decode } from "@/plugins/utils";

export default {
  data() {
    return {};
  },
  created() {
    this.login();
  },
  methods: {
    login() {
      console.log("请求登陆");
      // const { target, agentId } = this.$route.query
      // const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
      // window.location.href = apiBaseUrl + '/workbench/agent/auth?agentId=' + agentId + '&target=' + encodeURIComponent(target)
      //var appid = "ww915f4ddc9258dcbb";
      var appid = "ww251abc79bb5b1474";//线上
      var redirectUrl = encodeURIComponent(location.href);
      //构造请求链接
      var url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appid +
        "&redirect_uri=" +
        redirectUrl +
        "&response_type=code&scope=snsapi_privateinfo&state=STATE#wechat_redirect";
      console.log("跳转成功url", url);
      window.location.href = url;
    },
  },
};
</script>
