import request from "@/utils/request";
/**
 * 品牌
 */
export class brandApi {
    // 获取品牌列表
    static brandList(params) {
        return request({
            url: "/corpBrand/record",
            method: "get",
            params: params,
        });
    }
    // 获取分类列表
    static classifyList(params) {
        return request({
            url: "/corpBrand/category",
            method: "get",
            params: params,
        });
    }
    // 获取品牌详情
    static brandDetail(params) {
        return request({
            url: "/corpBrand/detail",
            method: "get",
            params: params,
        });
    }
    // 获取你感兴趣的品牌列表
    static interestBrand(params) {
        return request({
            url: "/corpBrand/interest",
            method: "get",
            params: params,
        });
    }
}
