import request from "@/utils/request";
/**
 * 我的
 */
export class qyChanceApi {
    // 商机列表
    static chanceList(params) {
        return request({
            url: "/manager/businessOpportunitiesList",
            method: "get",
            params: params,
        });
    }
    // 商机详情
    static ChanceDetail(params) {
        return request({
            url: "/manager/businessOpportunitiesDetail",
            method: "get",
            params: params,
        });
    }
    // 抢商机评论列表
    static grabChanceComment(params) {
        return request({
            url: "/manager/businessOpportunitiesCommentList",
            method: "get",
            params: params,
        });
    }
        // 抢商机企业列表
        static grabCorpList(params) {
            return request({
                url: "/manager/grabCorpList",
                method: "post",
                data: params,
            });
        }
    // 下架商机
    static closeChance(params) {
        return request({
            url: "/manager/closeBusinessOpportunities",
            method: "post",
            data: params,
        });
    }
    // 商机详情
    static ChanceDetail(params) {
        return request({
            url: "/manager/businessOpportunitiesDetail",
            method: "get",
            params: params,
        });
    }   
}