
<template></template>
<script>
const option = [
  { name: "	北京市	", id: "1" },
  { name: "	天津市	", id: "2" },
  { name: "	石家庄市	", id: "3" },
  { name: "	唐山市	", id: "4" },
  { name: "	秦皇岛市	", id: "5" },
  { name: "	邯郸市	", id: "6" },
  { name: "	邢台市	", id: "7" },
  { name: "	保定市	", id: "8" },
  { name: "	张家口市	", id: "9" },
  { name: "	承德市	", id: "10" },
  { name: "	沧州市	", id: "11" },
  { name: "	廊坊市	", id: "12" },
  { name: "	衡水市	", id: "13" },
  { name: "	省直辖县	", id: "14" },
  { name: "	太原市	", id: "15" },
  { name: "	大同市	", id: "16" },
  { name: "	阳泉市	", id: "17" },
  { name: "	长治市	", id: "18" },
  { name: "	晋城市	", id: "19" },
  { name: "	朔州市	", id: "20" },
  { name: "	晋中市	", id: "21" },
  { name: "	运城市	", id: "22" },
  { name: "	忻州市	", id: "23" },
  { name: "	临汾市	", id: "24" },
  { name: "	吕梁市	", id: "25" },
  { name: "	呼和浩特市	", id: "26" },
  { name: "	包头市	", id: "27" },
  { name: "	乌海市	", id: "28" },
  { name: "	赤峰市	", id: "29" },
  { name: "	通辽市	", id: "30" },
  { name: "	鄂尔多斯市	", id: "31" },
  { name: "	呼伦贝尔市	", id: "32" },
  { name: "	巴彦淖尔市	", id: "33" },
  { name: "	乌兰察布市	", id: "34" },
  { name: "	兴安盟	", id: "35" },
  { name: "	锡林郭勒盟	", id: "36" },
  { name: "	阿拉善盟	", id: "37" },
  { name: "	沈阳市	", id: "38" },
  { name: "	大连市	", id: "39" },
  { name: "	鞍山市	", id: "40" },
  { name: "	抚顺市	", id: "41" },
  { name: "	本溪市	", id: "42" },
  { name: "	丹东市	", id: "43" },
  { name: "	锦州市	", id: "44" },
  { name: "	营口市	", id: "45" },
  { name: "	阜新市	", id: "46" },
  { name: "	辽阳市	", id: "47" },
  { name: "	盘锦市	", id: "48" },
  { name: "	铁岭市	", id: "49" },
  { name: "	朝阳市	", id: "50" },
  { name: "	葫芦岛市	", id: "51" },
  { name: "	长春市	", id: "52" },
  { name: "	吉林市	", id: "53" },
  { name: "	四平市	", id: "54" },
  { name: "	辽源市	", id: "55" },
  { name: "	通化市	", id: "56" },
  { name: "	白山市	", id: "57" },
  { name: "	松原市	", id: "58" },
  { name: "	白城市	", id: "59" },
  { name: "	延边朝鲜族自治州	", id: "60" },
  { name: "	哈尔滨市	", id: "61" },
  { name: "	齐齐哈尔市	", id: "62" },
  { name: "	鸡西市	", id: "63" },
  { name: "	鹤岗市	", id: "64" },
  { name: "	双鸭山市	", id: "65" },
  { name: "	大庆市	", id: "66" },
  { name: "	伊春市	", id: "67" },
  { name: "	佳木斯市	", id: "68" },
  { name: "	七台河市	", id: "69" },
  { name: "	牡丹江市	", id: "70" },
  { name: "	黑河市	", id: "71" },
  { name: "	绥化市	", id: "72" },
  { name: "	大兴安岭地区	", id: "73" },
  { name: "	上海市	", id: "74" },
  { name: "	南京市	", id: "75" },
  { name: "	无锡市	", id: "76" },
  { name: "	徐州市	", id: "77" },
  { name: "	常州市	", id: "78" },
  { name: "	苏州市	", id: "79" },
  { name: "	南通市	", id: "80" },
  { name: "	连云港市	", id: "81" },
  { name: "	淮安市	", id: "82" },
  { name: "	盐城市	", id: "83" },
  { name: "	扬州市	", id: "84" },
  { name: "	镇江市	", id: "85" },
  { name: "	泰州市	", id: "86" },
  { name: "	宿迁市	", id: "87" },
  { name: "	杭州市	", id: "88" },
  { name: "	宁波市	", id: "89" },
  { name: "	温州市	", id: "90" },
  { name: "	嘉兴市	", id: "91" },
  { name: "	湖州市	", id: "92" },
  { name: "	绍兴市	", id: "93" },
  { name: "	金华市	", id: "94" },
  { name: "	衢州市	", id: "95" },
  { name: "	舟山市	", id: "96" },
  { name: "	台州市	", id: "97" },
  { name: "	丽水市	", id: "98" },
  { name: "	合肥市	", id: "99" },
  { name: "	芜湖市	", id: "100" },
  { name: "	蚌埠市	", id: "101" },
  { name: "	淮南市	", id: "102" },
  { name: "	马鞍山市	", id: "103" },
  { name: "	淮北市	", id: "104" },
  { name: "	铜陵市	", id: "105" },
  { name: "	安庆市	", id: "106" },
  { name: "	黄山市	", id: "107" },
  { name: "	滁州市	", id: "108" },
  { name: "	阜阳市	", id: "109" },
  { name: "	宿州市	", id: "110" },
  { name: "	六安市	", id: "111" },
  { name: "	亳州市	", id: "112" },
  { name: "	池州市	", id: "113" },
  { name: "	宣城市	", id: "114" },
  { name: "	福州市	", id: "115" },
  { name: "	厦门市	", id: "116" },
  { name: "	莆田市	", id: "117" },
  { name: "	三明市	", id: "118" },
  { name: "	泉州市	", id: "119" },
  { name: "	漳州市	", id: "120" },
  { name: "	南平市	", id: "121" },
  { name: "	龙岩市	", id: "122" },
  { name: "	宁德市	", id: "123" },
  { name: "	南昌市	", id: "124" },
  { name: "	景德镇市	", id: "125" },
  { name: "	萍乡市	", id: "126" },
  { name: "	九江市	", id: "127" },
  { name: "	新余市	", id: "128" },
  { name: "	鹰潭市	", id: "129" },
  { name: "	赣州市	", id: "130" },
  { name: "	吉安市	", id: "131" },
  { name: "	宜春市	", id: "132" },
  { name: "	抚州市	", id: "133" },
  { name: "	上饶市	", id: "134" },
  { name: "	济南市	", id: "135" },
  { name: "	青岛市	", id: "136" },
  { name: "	淄博市	", id: "137" },
  { name: "	枣庄市	", id: "138" },
  { name: "	东营市	", id: "139" },
  { name: "	烟台市	", id: "140" },
  { name: "	潍坊市	", id: "141" },
  { name: "	济宁市	", id: "142" },
  { name: "	泰安市	", id: "143" },
  { name: "	威海市	", id: "144" },
  { name: "	日照市	", id: "145" },
  { name: "	莱芜市	", id: "146" },
  { name: "	临沂市	", id: "147" },
  { name: "	德州市	", id: "148" },
  { name: "	聊城市	", id: "149" },
  { name: "	滨州市	", id: "150" },
  { name: "	菏泽市	", id: "151" },
  { name: "	郑州市	", id: "152" },
  { name: "	开封市	", id: "153" },
  { name: "	洛阳市	", id: "154" },
  { name: "	平顶山市	", id: "155" },
  { name: "	安阳市	", id: "156" },
  { name: "	鹤壁市	", id: "157" },
  { name: "	新乡市	", id: "158" },
  { name: "	焦作市	", id: "159" },
  { name: "	濮阳市	", id: "160" },
  { name: "	许昌市	", id: "161" },
  { name: "	漯河市	", id: "162" },
  { name: "	三门峡市	", id: "163" },
  { name: "	南阳市	", id: "164" },
  { name: "	商丘市	", id: "165" },
  { name: "	信阳市	", id: "166" },
  { name: "	周口市	", id: "167" },
  { name: "	驻马店市	", id: "168" },
  { name: "	省直辖县	", id: "169" },
  { name: "	武汉市	", id: "170" },
  { name: "	黄石市	", id: "171" },
  { name: "	十堰市	", id: "172" },
  { name: "	宜昌市	", id: "173" },
  { name: "	襄阳市	", id: "174" },
  { name: "	鄂州市	", id: "175" },
  { name: "	荆门市	", id: "176" },
  { name: "	孝感市	", id: "177" },
  { name: "	荆州市	", id: "178" },
  { name: "	黄冈市	", id: "179" },
  { name: "	咸宁市	", id: "180" },
  { name: "	随州市	", id: "181" },
  { name: "	恩施土家族苗族自治州	", id: "182" },
  { name: "	省直辖县	", id: "183" },
  { name: "	长沙市	", id: "184" },
  { name: "	株洲市	", id: "185" },
  { name: "	湘潭市	", id: "186" },
  { name: "	衡阳市	", id: "187" },
  { name: "	邵阳市	", id: "188" },
  { name: "	岳阳市	", id: "189" },
  { name: "	常德市	", id: "190" },
  { name: "	张家界市	", id: "191" },
  { name: "	益阳市	", id: "192" },
  { name: "	郴州市	", id: "193" },
  { name: "	永州市	", id: "194" },
  { name: "	怀化市	", id: "195" },
  { name: "	娄底市	", id: "196" },
  { name: "	湘西土家族苗族自治州	", id: "197" },
  { name: "	广州市	", id: "198" },
  { name: "	韶关市	", id: "199" },
  { name: "	深圳市	", id: "200" },
  { name: "	珠海市	", id: "201" },
  { name: "	汕头市	", id: "202" },
  { name: "	佛山市	", id: "203" },
  { name: "	江门市	", id: "204" },
  { name: "	湛江市	", id: "205" },
  { name: "	茂名市	", id: "206" },
  { name: "	肇庆市	", id: "207" },
  { name: "	惠州市	", id: "208" },
  { name: "	梅州市	", id: "209" },
  { name: "	汕尾市	", id: "210" },
  { name: "	河源市	", id: "211" },
  { name: "	阳江市	", id: "212" },
  { name: "	清远市	", id: "213" },
  { name: "	东莞市	", id: "214" },
  { name: "	中山市	", id: "215" },
  { name: "	潮州市	", id: "216" },
  { name: "	揭阳市	", id: "217" },
  { name: "	云浮市	", id: "218" },
  { name: "	南宁市	", id: "219" },
  { name: "	柳州市	", id: "220" },
  { name: "	桂林市	", id: "221" },
  { name: "	梧州市	", id: "222" },
  { name: "	北海市	", id: "223" },
  { name: "	防城港市	", id: "224" },
  { name: "	钦州市	", id: "225" },
  { name: "	贵港市	", id: "226" },
  { name: "	玉林市	", id: "227" },
  { name: "	百色市	", id: "228" },
  { name: "	贺州市	", id: "229" },
  { name: "	河池市	", id: "230" },
  { name: "	来宾市	", id: "231" },
  { name: "	崇左市	", id: "232" },
  { name: "	海口市	", id: "233" },
  { name: "	三亚市	", id: "234" },
  { name: "	三沙市	", id: "235" },
  { name: "	儋州市	", id: "236" },
  { name: "	省直辖县	", id: "237" },
  { name: "	重庆市	", id: "238" },
  { name: "	县	", id: "239" },
  { name: "	成都市	", id: "240" },
  { name: "	自贡市	", id: "241" },
  { name: "	攀枝花市	", id: "242" },
  { name: "	泸州市	", id: "243" },
  { name: "	德阳市	", id: "244" },
  { name: "	绵阳市	", id: "245" },
  { name: "	广元市	", id: "246" },
  { name: "	遂宁市	", id: "247" },
  { name: "	内江市	", id: "248" },
  { name: "	乐山市	", id: "249" },
  { name: "	南充市	", id: "250" },
  { name: "	眉山市	", id: "251" },
  { name: "	宜宾市	", id: "252" },
  { name: "	广安市	", id: "253" },
  { name: "	达州市	", id: "254" },
  { name: "	雅安市	", id: "255" },
  { name: "	巴中市	", id: "256" },
  { name: "	资阳市	", id: "257" },
  { name: "	阿坝藏族羌族自治州	", id: "258" },
  { name: "	甘孜藏族自治州	", id: "259" },
  { name: "	凉山彝族自治州	", id: "260" },
  { name: "	贵阳市	", id: "261" },
  { name: "	六盘水市	", id: "262" },
  { name: "	遵义市	", id: "263" },
  { name: "	安顺市	", id: "264" },
  { name: "	毕节市	", id: "265" },
  { name: "	铜仁市	", id: "266" },
  { name: "	黔西南布依族苗族自治州	", id: "267" },
  { name: "	黔东南苗族侗族自治州	", id: "268" },
  { name: "	黔南布依族苗族自治州	", id: "269" },
  { name: "	昆明市	", id: "270" },
  { name: "	曲靖市	", id: "271" },
  { name: "	玉溪市	", id: "272" },
  { name: "	保山市	", id: "273" },
  { name: "	昭通市	", id: "274" },
  { name: "	丽江市	", id: "275" },
  { name: "	普洱市	", id: "276" },
  { name: "	临沧市	", id: "277" },
  { name: "	楚雄彝族自治州	", id: "278" },
  { name: "	红河哈尼族彝族自治州	", id: "279" },
  { name: "	文山壮族苗族自治州	", id: "280" },
  { name: "	西双版纳傣族自治州	", id: "281" },
  { name: "	大理白族自治州	", id: "282" },
  { name: "	德宏傣族景颇族自治州	", id: "283" },
  { name: "	怒江傈僳族自治州	", id: "284" },
  { name: "	迪庆藏族自治州	", id: "285" },
  { name: "	拉萨市	", id: "286" },
  { name: "	日喀则市	", id: "287" },
  { name: "	昌都市	", id: "288" },
  { name: "	林芝市	", id: "289" },
  { name: "	山南市	", id: "290" },
  { name: "	那曲市	", id: "291" },
  { name: "	阿里地区	", id: "292" },
  { name: "	西安市	", id: "293" },
  { name: "	铜川市	", id: "294" },
  { name: "	宝鸡市	", id: "295" },
  { name: "	咸阳市	", id: "296" },
  { name: "	渭南市	", id: "297" },
  { name: "	延安市	", id: "298" },
  { name: "	汉中市	", id: "299" },
  { name: "	榆林市	", id: "300" },
  { name: "	安康市	", id: "301" },
  { name: "	商洛市	", id: "302" },
  { name: "	兰州市	", id: "303" },
  { name: "	嘉峪关市	", id: "304" },
  { name: "	金昌市	", id: "305" },
  { name: "	白银市	", id: "306" },
  { name: "	天水市	", id: "307" },
  { name: "	武威市	", id: "308" },
  { name: "	张掖市	", id: "309" },
  { name: "	平凉市	", id: "310" },
  { name: "	酒泉市	", id: "311" },
  { name: "	庆阳市	", id: "312" },
  { name: "	定西市	", id: "313" },
  { name: "	陇南市	", id: "314" },
  { name: "	临夏回族自治州	", id: "315" },
  { name: "	甘南藏族自治州	", id: "316" },
  { name: "	西宁市	", id: "317" },
  { name: "	海东市	", id: "318" },
  { name: "	海北藏族自治州	", id: "319" },
  { name: "	黄南藏族自治州	", id: "320" },
  { name: "	海南藏族自治州	", id: "321" },
  { name: "	果洛藏族自治州	", id: "322" },
  { name: "	玉树藏族自治州	", id: "323" },
  { name: "	海西蒙古族藏族自治州	", id: "324" },
  { name: "	银川市	", id: "325" },
  { name: "	石嘴山市	", id: "326" },
  { name: "	吴忠市	", id: "327" },
  { name: "	固原市	", id: "328" },
  { name: "	中卫市	", id: "329" },
  { name: "	乌鲁木齐市	", id: "330" },
  { name: "	克拉玛依市	", id: "331" },
  { name: "	吐鲁番市	", id: "332" },
  { name: "	哈密市	", id: "333" },
  { name: "	昌吉回族自治州	", id: "334" },
  { name: "	博尔塔拉蒙古自治州	", id: "335" },
  { name: "	巴音郭楞蒙古自治州	", id: "336" },
  { name: "	阿克苏地区	", id: "337" },
  { name: "	克孜勒苏柯尔克孜自治州	", id: "338" },
  { name: "	喀什地区	", id: "339" },
  { name: "	和田地区	", id: "340" },
  { name: "	伊犁哈萨克自治州	", id: "341" },
  { name: "	塔城地区	", id: "342" },
  { name: "	阿勒泰地区	", id: "343" },
  { name: "	自治区直辖县级行政区划	", id: "344" },
  { name: "	台北市	", id: "345" },
  { name: "	高雄市	", id: "346" },
  { name: "	台南市	", id: "347" },
  { name: "	台中市	", id: "348" },
  { name: "	金门县	", id: "349" },
  { name: "	南投县	", id: "350" },
  { name: "	基隆市	", id: "351" },
  { name: "	新竹市	", id: "352" },
  { name: "	嘉义市	", id: "353" },
  { name: "	新北市	", id: "354" },
  { name: "	宜兰县	", id: "355" },
  { name: "	新竹县	", id: "356" },
  { name: "	桃园县	", id: "357" },
  { name: "	苗栗县	", id: "358" },
  { name: "	彰化县	", id: "359" },
  { name: "	嘉义县	", id: "360" },
  { name: "	云林县	", id: "361" },
  { name: "	屏东县	", id: "362" },
  { name: "	台东县	", id: "363" },
  { name: "	花莲县	", id: "364" },
  { name: "	澎湖县	", id: "365" },
  { name: "	连江县	", id: "366" },
  { name: "	香港岛	", id: "367" },
  { name: "	九龙	", id: "368" },
  { name: "	新界	", id: "369" },
  { name: "	澳门半岛	", id: "370" },
];
export default {
  option,
};
</script>