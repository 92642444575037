<template>
  <div class="feedBack">
    <div class="banxin">
      <div class="top">
        <van-field
          v-model="message"
          row="10"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入您的意见反馈，我们尽快与你联系！"
          show-word-limit
        />
      </div>
      <div class="bottom">
        <van-field
          style="margin-top: 16px; border-radius: 5px"
          v-model="phone"
          placeholder="请输入联系方式"
        />
      </div>
      <div class="btn" @click="submit"> 提 交 </div>
    </div>
  </div>
</template>
<script>
import { myApi } from '../../api/my';
export default {
  data() {
    return {
      message: "",
      phone: "",
    };
  },
  methods: {
    submit(){
      myApi.feedBack({content:this.message,mobile:this.phone}).then(res=>{
        this.$toast(res.sMsg)
        if(res.code==0){
          this.$router.go(-1)
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.feedBack {
  height: 100%;
  background-color: #f7f7f7;
}

.top {
  overflow: hidden;
  height: 100px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 18px;
}
.btn {
  width: 94%;
  position: fixed;
  bottom: 8px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  background: #3974c6;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
}
</style>