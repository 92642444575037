<template>
  <div class="detail">
    <div class="banxin">
      <h5>{{ detail.name }}</h5>
      <div class="top">
        <div class="left">
          来自：{{ detail.author_name }} &nbsp;&nbsp;阅读 {{ detail.view_num }}
        </div>
        <div class="right">
          {{ detail.create_time }}
        </div>
      </div>
      <div style="margin-top: 8px; width: 100%" v-html="detail.content"></div>
    </div>
  </div>
</template>
<script>
import { hyInfoApi } from "../../api/hyInfo";
export default {
  data() {
    return {
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id;
      hyInfoApi.hyInfoDetail({ id }).then((res) => {
        this.detail = res.data;
        console.log("详情", this.detail);
      });
    },
  },
};
</script>
<style lang="less" scoped>
h5 {
  margin: 0;
  height: 50px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
  line-height: 50px;
  text-align: center;
}
.top {
  display: flex;
  justify-content: space-between;
  height: 19px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #717174;
  line-height: 19px;
}
.left {
  width: 56%;
}
/deep/ img {
  width: 100% !important;
}
</style>