<template>
  <div class="brandInfo" style="background: #fff">
    <div class="main banxin">
      <div class="top">
        <!-- <div class="leftT">100+人最近联系</div> -->
        <div class="you" v-if="brandDetailInfo.recommend != ''">
          {{ brandDetailInfo.recommend }}
        </div>
        <h3>{{ brandDetailInfo.name }}</h3>
        <div
          class="topM"
          @click="
            () =>
              this.$router.push({
                path: '/companyDetail/index',
                query: { id: brandDetailInfo.corp_id },
              })
          "
        >
          <img src="../../img/brandInfo/已认证.png" alt="" />
          <span>&nbsp; {{ brandDetailInfo.corp_name }}></span>
        </div>
        <div class="topB">
          <span style="border-left: none" @click="showPop(brandDetailInfo.qr_code)"
            >联系企业</span
          >
          <a :href="brandDetailInfo.official_website"><span>企业官网</span></a>
        </div>
        <van-popup
          v-model="showCode"
          closeable
          style="
            width: 260px;
            height: 250px;
            text-align: center;
            margin-left: 45%;
          "
        >
          <img
            style="
              margin-top: 30px;
              width: 200px;
              height: 200px;
              text-align: center;
            "
            :src="brandDetailInfo.qr_code"
            alt=""
          />
        </van-popup>
      </div>
      <!-- 企业信息 -->
      <div class="Info">
        <div class="Ctop">
          <div class="left">企业信息</div>
        </div>
        <div class="Cmiddle">
          <div class="content">
            <div class="cCenter">
              <div class="box">
                <div class="m17">
                  <span>企业全称:</span>
                  <span>{{ brandDetailInfo.corp_name }}</span>
                </div>
                <div class="m17">
                  <span>法人:</span>
                  <span>{{ brandDetailInfo.legal_name }}</span>
                </div>
                <div class="m17">
                  <span>地址:</span> <span>{{ brandDetailInfo.address }}</span>
                </div>
                <div class="m17">
                  <span>经营范围:</span>
                  <span>{{ brandDetailInfo.industry_category }}</span>
                </div>
                <div class="m17">
                  <span>简介:</span>
                </div>
                <img
                  :src="brandDetailInfo.introduction_img"
                  alt=""
                  style="width: 100%; height: 128px; border-radius: 10px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业联系人 -->
      <div class="source">
        <div class="Stop">
          <div class="left">企业联系人</div>
        </div>
        <div class="Smiddle" v-if="contactInfo.length > 0">
          <div
            class="Mcontent"
            v-for="(item, index) in contactInfo"
            :key="index"
          >
            <div class="Mleft">
              <img :src="item.avatar" alt="" />
              <span
                >&nbsp;<ww-open-data
                  type="userName"
                  :openid="item.name"
                  :corpid="item.corp_id"
              /></span>
            </div>
            <div class="Mright">
              <button
                ref="btn"
                v-bind:disabled="dis"
                @click="add(item.id, index)"
              >
                申请添加
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="空空如也~" />
        </div>
      </div>
      <!-- 企业商机 -->
      <div class="chance">
        <div class="Ctop">
          <div class="left">企业商机</div>
        </div>
        <div v-if="chance.length > 0">
          <div
            class="Cmiddle"
            v-for="(item, index) in chance"
            :key="index"
            @click.stop="skipChanceDetail(item.id)"
          >
            <div class="content">
              <div class="ctop">
                <h1>{{ item.name }}</h1>
                <p>
                  {{ item.generalize }}
                </p>
              </div>
              <div class="cCenter">
                <div class="box">
                  <div class="m17">
                    <span>初步预算:</span>
                    <i
                      >{{ item.budget_range_start }}-{{
                        item.budget_range_end
                      }}</i
                    >
                  </div>
                  <div class="m17">
                    <span>需求时间:</span>
                    <span
                      >{{ item.start_time.slice(0, 10) }} —
                      {{ item.end_time.slice(0, 10) }}</span
                    >
                  </div>
                  <div class="m17">
                    <span>区域要求:</span> <span>{{ item.area }}</span>
                  </div>
                  <div class="m17">
                    <img :src="item.corp_logo" alt="" />&nbsp;<em>{{
                      item.corp_name
                    }}</em>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="bl">
                  <span>已抢：{{ item.grab_num }}人</span>
                </div>
                <button
                  style="margin-top: -4px"
                  @click.stop="skipGrap(item.id)"
                >
                  抢商机
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="空空如也~" />
        </div>
      </div>
    </div>
    <!-- 底部部分 -->
    <div class="last">
      <div v-for="(item, index) in bottomMenu" :key="index" style="width: 25%">
        <a :href="item.content">
          <div class="div">
            <img :src="item.picture" alt="" />
            <span> {{ item.name }}</span>
          </div>
        </a>
      </div>
      <!-- <div>
        <img src="../../img/brandInfo/zixuntianchong.png" alt="" />
        <span> 行业资讯</span>
      </div>
      <div>
        <img src="../../img/brandInfo/iconfontevaluate.png" alt="" />
        <span> 企业评价</span>
      </div>
      <div>
        <img src="../../img/brandInfo/-fuwu-xianxing.png" alt="" />
        <span> 其他服务</span>
      </div> -->
    </div>
  </div>
</template>
<script>
import { brandApi } from "../../api/brand";
import { companyApi } from "../../api/company";
import { initAgentConfig } from "../../utils/wxCodeAuth";

export default {
  data() {
    return {
      brandDetailInfo: "",
      contactInfo: [],
      chance: [],
      bottomMenu: [],
      dis: false,
      showCode: false,
    };
  },
  created() {
    this.getBrandDetail();
    initAgentConfig();
  },
  methods: {
    showPop(item){
      if(item!=''){
        this.showCode=true
      }
    },
    getBrandDetail() {
      const brandId = location.search.split("=")[1];
      brandApi.brandDetail({ id: brandId }).then((res) => {
        console.log("品牌详情", res);
        this.brandDetailInfo = res.data.detail;
        this.contactInfo = res.data.contacts.data;
        this.chance = res.data.businessOpportunities.data;
        this.bottomMenu = res.data.bottomMenu;
      });
    },
    add(id, index) {
      companyApi.applyAdd({ id }).then((res) => {
        console.log("添加", res);
        if (res.code == 1) {
          let butt = [];
          butt = this.$refs.btn;
          butt.map((item, i) => {
            if (index == i) {
              this.dis = true;
              item.style.backgroundColor = "#ccc";
            }
          });
          this.$toast.success("发送请求成功");
        } else {
          this.$toast.fail(res.sMsg);
        }
      });
    },
    skipChanceDetail(id) {
      this.$router.push({ path: "/chanceDetail/index", query: { id: id } });
    },
    skipGrap(id) {
      this.$router.push({ path: "/grapMessage/index", query: { id } });
      return;
    },
  },
};
</script>
<style lang="less" scoped>
.brandInfo {
  width: 100%;
  overflow-x: hidden;
}
.main {
  overflow: hidden;
}
.top {
  position: relative;
  margin-top: 10px;
  height: 118px;
  background: #f2f8ff;
  border-radius: 5px;
  overflow: hidden;
  .leftT {
    height: 20px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #8f8f8f;
    line-height: 20px;
  }
  .you {
    position: absolute;
    top: 10px;
    right: 0;
    text-align: center;
    color: #fff;
    font-size: 12px;
    width: 59px;
    height: 18px;
    background: #f5a623;
    border-radius: 50px 0px 0px 50px;
  }
  h3 {
    margin-top: 9px;
    height: 21px;
    font-size: 15px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #000000;
    line-height: 21px;
    text-align: center;
  }
  .topM {
    height: 20px;
    line-height: 20px;
    text-align: center;
    img {
      width: 40px;
      height: 14px;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #555555;
    }
  }
  .topB {
    margin-top: 8px;
    width: 100%;
    text-align: center;
    span {
      display: inline-block;
      width: 50%;
      font-size: 13px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #555555;
      border-left: 1px solid #979797;
    }
  }
}

// 企业信息
.Info {
  .Ctop {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3974c6;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
  }
  .Cmiddle {
    width: 100%;
    //  margin-top: 10px;
    min-height: 272px;
    background: #f2f8ff;
    border-radius: 4px;
    overflow: hidden;
    .content {
      margin: 9px auto;
      width: 94%;
      h1 {
        width: 100%;
        height: 21px;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #4a4a4a;
        line-height: 21px;
      }
      p {
        width: 100%;
        min-height: 48px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 19px;
      }
      .cCenter {
        width: 100%;
        min-height: 107px;
        border-radius: 5px;
        .box {
          width: 97%;
          margin: 8px auto;
          .m17 {
            min-height: 26px;
            line-height: 18px;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 28px;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      margin-top: 8px;
      .bl {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e84a10;
        line-height: 20px;
      }
      button {
        border: none;
        font-size: 14px;
        color: #fff;
        width: 86px;
        height: 24px;
        line-height: 20px;
        background: #f5a623;
        border-radius: 12px;
      }
    }
  }
}
// 企业联系人
.source {
  .Stop {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3974c6;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
  }
  .Smiddle {
    overflow: hidden;
    min-height: 107px;
    background: #f2f8ff;
    border-radius: 4px;
    .Mcontent {
      margin: 10px auto;
      width: 91%;
      display: flex;
      justify-content: space-between;
      .Mleft {
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 50%;
        }
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
        }
      }
      .Mright {
        button {
          border: none;
          width: 86px;
          height: 24px;
          background: #3974c6;
          border-radius: 12px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }
}
// 企业商机
.chance {
  .Ctop {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      height: 21px;
      padding-left: 8px;
      border-left: 2px solid #3974c6;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 21px;
    }
  }

  .Cmiddle {
    margin-bottom: 10px;
    min-height: 200px;
    background: #f9f9f9;
    border-radius: 5px;
    overflow: hidden;
    .content {
      margin: 9px auto;
      width: 90%;
      .ctop {
        width: 100%;
        // height: 70px;
        h1 {
          // width: 315px;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          // width: 334px;
          width: 100%;
          min-height: 20px;
          margin: 0;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 19px;
        }
      }
      .cCenter {
        // width: 323px;
        width: 100%;
        min-height: 107px;
        background: #ffffff;
        border-radius: 5px;
        .box {
          // width: 313px;
          width: 96%;
          margin: 8px auto;
          .m17 {
            height: 20px;
            img {
              width: 16px;
              height: 16px;
            }
          }
          span {
            height: 19px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
          }
          i {
            height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #e84a10;
            line-height: 20px;
          }
          em {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 28px;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      margin-top: 8px;
      .bl {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e84a10;
        line-height: 20px;
      }
      button {
        border: none;
        font-size: 14px;
        color: #fff;
        width: 86px;
        height: 24px;
        line-height: 20px;
        background: #f5a623;
        border-radius: 12px;
      }
    }
  }
}
.last {
  overflow: hidden;
  width: 100%;
  height: 79px;
  // background: #F5F6F8;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  // margin-left: 36px;
  .div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    img {
      width: 23px;
      height: 23px;
      margin-bottom: 4px;
    }
    span {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #3974c6;
    }
  }
}
i,
em,
b {
  font-style: normal;
}
</style>