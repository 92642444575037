<template>
  <div class="applyUse">
    <div class="top">申请成功后可在我的咨询中查看预约信息。</div>
    <div class="banxin">
      <div class="middle">权益券：{{rightName}}</div>
      <div class="section">
        <span class="title">意向咨询权益</span
        ><van-dropdown-menu>
          <van-dropdown-item v-model="value1" @change="selectClassify" :options="option1" />
          <van-dropdown-item v-model="value2" :options="option2" placerhold="请选择类型" />
        </van-dropdown-menu>
      </div>
      <div class="title">咨询时间</div>
      <div class="list-item">
        <div class="item-content">
          <div
            @click="$refs.starttime.$show()"
            style="flex: 1; text-align: center"
          >
            {{ sTime ? sTime : "开始时间" }}
          </div>
          -
          <div
            @click="$refs.endtime.$show()"
            style="flex: 1; text-align: center"
          >
            {{ endTime ? endTime : "结束时间" }}
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="title">备注</div>
        <van-field
        style="height:76px;background:#F9F9F9"
          v-model="message"
          rows="1"
          autosize
          type="textarea"
          placeholder="备注"
        />
      </div>
      <div class="last">
        <button class="btn1" @click="()=>this.$router.go(-1)">取 消</button>
        <button class="btn2" @click="confirm">确 定</button>
      </div>
    </div>
    <ComaddTime ref="starttime" @change="choseStartTime" />
    <ComaddTime ref="endtime" @change="choseEndTime" />
  </div>
</template>
<script>
import {myRightApi} from '../../api/myRight'
export default {
  data() {
    return {
      rightName:'',
      value1: 0,
      value2: 0,
      message: '',
       sTime: '',
      endTime: '',
      id:'',
      rightDetailInfo:{},
      option1: [],
      option2: [],
    };
  },
  created() {
    this.rightName=this.$route.query.rightName
    this.getData();
    this.getRightType()
    this.selectClassify()
  },
  methods: {
    selectClassify(){    
      myRightApi.rightClassify({id : this.value1!=0?this.value1:0}).then(res=>{
        console.log('权益分类名称',res)
        res.data.unshift({ id: 0, name: "权益分类" });
        this.option2=res.data.map(item=>{ return {text:item.name,value:item.id}})
        console.log('权益类型2',this.option2)
        // this.value2 = this.option2[0].value;
        console.log('this.value',this.value2)
      })
    },
    getRightType(){
      myRightApi.rightType().then(res=>{
        res.data.unshift({ id: 0, name: "权益类型" });
        this.option1=res.data.map(item=>{ return {text:item.name,value:item.id}})
        console.log('权益类型1',this.option1)
        // this.value1 = this.option1[0].value;
        console.log('this.value1',this.value1)
      })
    },
    getData() {
      this.id=this.$route.query.id
      console.log('this.id',this.id)
      myRightApi.myRightDetail({id:this.id}).then((res) => {
        // this.value1=res.data.consultServiceCategory.id
        console.log("权益详情", res);
        this.rightDetailInfo=res.data
        // this.value2=res.data.consultService.id
      });
    },
    confirm(){
      myRightApi.applyUse({id:this.id,apply_consult_service_id:this.value1,apply_consult_service_category_id:this.value2,apply_start_time:this.sTime,apply_end_time:this.endTime,apply_remark:this.message}).then(res=>{
        if(res.code==0){
          this.$toast(res.sMsg)
          this.$router.push({path:'/myRight/index'})
        }
        else{
          this.$toast(res.sMsg)
        }
      })
    },
    choseStartTime(data) {
      console.log("返回的时间", data);
      this.sTime = data;
    },
    choseEndTime(data) {
      console.log("返回的时间", data);
      this.endTime = data;
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #f9f9f9;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #666666;
  text-indent: 15px;
}
.middle {
  height: 32px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
}
.title {
    margin-top: 10px;
    margin-bottom: 10px;
  height: 19px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #323232;
}
// 时间选择
.list-item {
  margin-bottom: 10px;
}
.item-content {
  width: 100%;
  /* background: red; */
  border: 1px solid #e5e6e7;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 7px;
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
}
.last {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 8px;
  width: 94%;
  .btn1 {
    border: none;
    width: 47%;
    height: 42px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #c1c9d0;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #c1c9d0;
  }
  .btn2 {
    border: none;
    width: 47%;
    height: 42px;
    background: #3974c6;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
  }
}
/deep/ .van-dropdown-menu__bar{
    height: 40px;
}
/deep/ .van-dropdown-menu__item{
    height: 40px;
}
/deep/ .van-popup--top{
    margin-top:75px
}
</style>