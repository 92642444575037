<template>
  <div class="banxin" style="margin-top: -12px">
    <div class="middle">
      <div class="Mtop">
        <div>
          <p>共 {{ grapList.length }} 个需求</p>
        </div>
        <div><img style="opacity: 0;" /></div>
      </div>
      <!-- 发布的商机 -->
      <div class="chance" v-for="(item, index) in grapList" :key="index" @click="skip(item.id)">
        <div class="Cmiddle">
          <div class="content">
            <div class="ctop">
              <h1>
                {{ item.name }}
              </h1>
              <p>
                {{ item.generalize }}
              </p>
            </div>
            <div class="cCenter">
              <div class="box">
                <div class="m17">
                  <span>初步预算:</span>
                  <i
                    >{{ item.budget_range_start }} -
                    {{ item.budget_range_end }}</i
                  >
                </div>
                <div class="m17">
                  <span>需求时间:</span>
                  <span
                    >{{ item.start_time.slice(0, 16) }} -
                    {{ item.end_time.slice(0, 16) }}</span
                  >
                </div>
                <div class="m17">
                  <span>区域要求:</span> <span>{{ item.area }}</span>
                </div>
                <div class="m17" style="margin-bottom;:5px">
                  <em>{{ item.create_time.slice(0, 16) }} &nbsp;</em>
                </div>
              </div>
            </div>
            <div
              class="bottom"
            >
              <div class="bl">
                <span>已抢：{{ item.grab_num }}人</span>
              </div>
              <!-- <button style="margin-top: -4px">下架商机</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { myApi } from "../../api/my";
export default {
  data() {
    return {
      active: 0,
      grapList: [],
    };
  },
  created() {
    this.getMygrap();
  },
  methods: {
    getMygrap() {
      myApi.myGrapList().then((res) => {
        console.log("myGrapList", res);
        this.grapList = res.data.data;
      });
    },
    skip(id){
      this.$router.push({path:'/myGrapChanceDetail/index',query:{id}})
    }
  },
};
</script>
<style lang="less" scoped>
// 顶部部分
.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    margin-top: 10px;
    width: 20px;
    height: 20px;
  }
  input {
    margin-top: 10px;
    border: none;
    background: #f9f9f9;
    width: 83%;
    height: 36px;
    border-radius: 20px;
    font-size: 12px;
    text-indent: 8px;
  }
}
.tab {
  margin-top: 12px;
  width: 100%;
  height: 42px;
  border: 1px solid #f1f1f1;
}
// 内容部分
.middle {
  flex: 1;
  overflow: auto;
  .Mtop {
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-bottom: -6px;
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
  // 发布的商机
  // 发布的商机
  .Cmiddle {
    margin-top: 10px;
    min-height: 200px;
    background: #f9f9f9;
    border-radius: 5px;
    overflow: hidden;
    .content {
      margin: 9px auto;
      width: 90%;
      .ctop {
        width: 100%;
        min-height: 50px;
        h1 {
          // width: 315px;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 21px;
        }
        p {
          // width: 334px;
          width: 100%;
          min-height: 20px;
          line-height: 18px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #555555;
          line-height: 19px;
        }
      }
      .cCenter {
        // width: 323px;
        width: 100%;
        min-height: 107px;
        background: #ffffff;
        border-radius: 5px;
        .box {
          // width: 313px;
          width: 96%;
          margin: 8px auto;
          overflow: hidden;
          .m17 {
            min-height: 20px;
            line-height: 18px;
            img {
              width: 16px;
              height: 16px;
            }
            em {
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #999999;
              line-height: 18px;
            }
          }
          span {
            height: 19px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
          }
          i {
            height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #e84a10;
            line-height: 20px;
          }
          em {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 28px;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      margin-top: 8px;
      .bl {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #e84a10;
        line-height: 20px;
      }
      button {
        border: none;
        font-size: 14px;
        color: #fff;
        width: 86px;
        height: 24px;
        line-height: 20px;
        background: #f5a623;
        border-radius: 12px;
      }
    }
  }


  i,
  em,
  b {
    font-style: normal;
  }
}
</style>