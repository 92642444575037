<template>
  <div class="myChance">
    <div class="banxin">
      <!-- 发布的商机 -->
      <div class="chance">
        <div class="Cmiddle">
          <div class="content">
            <div class="ctop">
              <h1>{{ myChanceDetailInfo.name }}</h1>
              <p>
                {{ myChanceDetailInfo.generalize }}
              </p>
            </div>
            <div class="cCenter">
              <div class="box">
                <div class="m17">
                  <span>初步预算: </span>
                  <i
                    >{{ myChanceDetailInfo.budget_range_start }} -
                    {{ myChanceDetailInfo.budget_range_end }}</i
                  >
                </div>
                <div class="m17">
                  <span>需求时间: </span>
                  <span
                    >{{ myChanceDetailInfo.start_time.slice(0, 16) }} -
                    {{ myChanceDetailInfo.end_time.slice(0, 16) }}</span
                  >
                </div>
                <div class="m17">
                  <span>区域要求: </span>
                  <span>{{ myChanceDetailInfo.area }}</span>
                </div>
                <!-- <div class="m17">
                  <em>2022/01/01 12:00 创建人：李四</em>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 抢订留言 -->
      <div v-if="myGrapListInfo.length > 0">
        <div
          class="liuyan"
          v-for="(item, index) in myGrapListInfo"
          :key="index"
        >
          <div class="m">
            <!-- <van-field
            style="background: #f9f9f9; min-height: 100px"
            v-model="message"
            autosize
            label="抢订留言:"
            type="textarea"
            show-word-limit
          /> -->
            <span>抢订留言：{{ item.comment }}</span>
          </div>
          <div class="lbottom">
            <div class="m16">
              <span>留言时间: {{ item.create_time }}</span>
            </div>
            <div class="m16">
              <span>企业信息: </span> <span>{{ item.grabCorp.name }}</span>
            </div>
            <div class="m16" @click="codePop">
              <span>添加联系人: </span>
              <div class="code"><i> &nbsp;<ww-open-data
                  type="userName"
                  :openid="item.grabEmployee.name"
                  :corpid="item.grabEmployee.corp_id"
              /> &nbsp;&nbsp;<img src="../myChanceDetail/qwCode.png" alt="" /> </i>&nbsp; </div>
            </div>
          </div>
          <van-popup
            v-model="showCode"
            closeable
            style="
              width: 260px;
              height: 400px;
              text-align: center;
              margin-left: 45%;
            "
          >
            <img :src="item.grabEmployee.qr_code" alt="" />
          </van-popup>
        </div>
      </div>
      <div v-else>
        <van-empty description="暂无企业抢订~" />
      </div>
      <!-- 平台声明 -->
      <div class="claim">
        本平台仅为企业之间真是商机信息流转平台，实现全国企业之间资源有效共享。请勿发布虚假信息；本平台不收取任何代收费用，企业间合作请参照企业合作合法流程操作，谨慎操作打款转账交易。
      </div>
      <div class="last" @click="offChance">下架商机</div>
    </div>
    <van-popup
      v-model="showGrapcompany"
      style="
        width: 260px;
        min-height: 300px;
        text-align: center;
        margin-left: 50%;
      "
    >
      <div class="Poptop">您本次合作的是哪家抢订企业呢？</div>
      <p class="Popp" :class="{ 'active':spanIndex1==index }" v-for="(item,index) in grapCompanyList" :key="index" @click="selectCorp(index)">{{item.name}}</p>
      <!-- <p class="Popp" @click="allAbove">以上都不是</p> -->
      <div class="Poplast">
        <button class="btn1" @click="()=>{showGrapcompany=false}">取消</button>
        <button class="btn2" @click="close">确定</button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { qyChanceApi } from "../../api/qyChance";
import { initAgentConfig } from "../../utils/wxCodeAuth";

export default {
  data() {
    return {
      spanIndex1: '',
      message: "",
      myChanceDetailInfo: {},
      myGrapListInfo: [],
      grapCompanyList:[],
      chanceId: "",
      showCode: false,
      showGrapcompany: false,
      str:''
    };
  },
  created() {
    initAgentConfig()
    this.getDetail();
    this.getGrapListInfo();
    this.getGrapList();
  },
  methods: {
    getDetail() {
      this.chanceId=this.$route.query.id;
      qyChanceApi.ChanceDetail({ id: this.chanceId }).then((res) => {
        console.log("我的商机详情", res);
        this.myChanceDetailInfo = res.data;
      });
    },
    // 抢订列表信息
    getGrapListInfo() {
      qyChanceApi.grabChanceComment({ id: this.chanceId }).then((res) => {
        console.log("抢订列表", res);
        this.myGrapListInfo = res.data.data;
      });
    },
    // 二维码弹窗
    codePop() {
      this.showCode = true;
    },
    // 抢订企业列表
    getGrapList() {
      qyChanceApi.grabCorpList({ id: this.chanceId }).then((res) => {
        console.log("抢订企业列表", res);
        this.grapCompanyList=res.data
        // this.grapCompanyList=[{id:7,name:'测试企业1'},{id:6,name:'测试企业2'},{id:5,name:'测试企业3'}]
      });
    },
    // 下架商机
    offChance() {
      if(this.grapCompanyList.length>0){
      this.showGrapcompany = true;   
      }
      else{
        qyChanceApi.closeChance({id:this.chanceId}).then(res=>{
        if(res.code==0){
          this.$toast(res.sMsg)
          this.$router.push({path:'/myMarket/index'})
        }
        else{
          this.$toast(res.sMsg)
        }     
      })
      }
    },
    allAbove(){
      console.log('allAbove')
    },
    selectCorp(index){
      this.spanIndex1=index
      console.log('this.this.spanIndex1',this.spanIndex1)    
      this.str=this.grapCompanyList[index].id
      console.log('arr',this.str)
    },
    close(){
      qyChanceApi.closeChance({id:this.chanceId,corp_id:this.str}).then(res=>{
        if(res.code==0){
          this.$toast(res.sMsg)
          this.showGrapcompany = false;
          this.$router.push({path:'/myMarket/index'})
        }
        else{
          this.$toast(res.sMsg)
        }     
      })
    }
  },
};
</script>
<style lang="less" scoped>
// 内容部分
// 发布的商机
.active{
  color:#3974C6 !important;
  background: #F2F8FF !important;
}
.chance {
  overflow: hidden;
}
.Cmiddle {
  margin-top: 16px;
  min-height: 170px;
  background: #f9f9f9;
  border-radius: 5px;
  overflow: hidden;
  .content {
    margin: 9px auto;
    width: 90%;
    .ctop {
      width: 100%;
      min-height: 50px;
      h1 {
        // width: 315px;
        height: 21px;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #4a4a4a;
        line-height: 21px;
      }
      p {
        // width: 334px;
        width: 100%;
        min-height: 20px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 19px;
      }
    }
    .cCenter {
      // width: 323px;
      width: 100%;
      min-height: 90px;
      background: #ffffff;
      border-radius: 5px;
      .box {
        // width: 313px;
        width: 96%;
        margin: 8px auto;
        overflow: hidden;
        .m17 {
          min-height: 20px;
          line-height: 20px;
          img {
            width: 16px;
            height: 16px;
          }
          em {
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #f5a623;
            line-height: 56px;
          }
        }
        span {
          height: 19px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 19px;
        }
        i {
          height: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #e84a10;
          line-height: 20px;
        }
        em {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
        }
      }
    }
  }
}

i,
em,
b {
  font-style: normal;
}
// 抢订留言
.liuyan {
  margin-top: 10px;
  min-height: 135px;
  background: #f9f9f9;
  border-radius: 5px;
  .m {
    min-height: 50px;
    span {
      text-indent: 12px;
      display: inline-block;
      height: 19px;
      font-size: 13px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 19px;
    }
  }
  .lbottom {
    // padding-top: 8px;
    padding-left: 15px;
    border-top: 1px solid #ccc;
    height: 76px;
    .m16 {
      line-height: 18px;
      height: 20px;
      .code{
        display: inline-block;
        border: 1px solid #3974c6;
        height: 19px;
        line-height: 19px;
        margin-top: 5px;
        line-height: 10px;
      }
      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #4a4a4a;
        line-height: 20px;
      }
      img{
        width: 16px;
        height: 14px;
      }
      i {
        font-size: 12px;
        display: inline-block;
        // width: 70px;
        height: 19px;
        border-radius: 2px;
        
      }
    }
  }
}
// 平台声明
.claim {
  margin-top: 10px;
  height: 93px;
  font-size: 12px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #999999;
  line-height: 19px;
}
.last {
  position: fixed;
  bottom: 8px;
  width: 94%;
  line-height: 42px;
  text-align: center;
  height: 42px;
  background: #3974c6;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
}
.Poptop {
  margin-top: 10px;
  height: 19px;
  font-size: 13px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
}
.Popp {
  margin: 0 auto;
  margin-top: 20px;
  width: 192px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #f9f9f9;
  border-radius: 12px;
  font-size: 12px;
  color: #999999;;
}
.PoppY{
background: #F2F8FF;
}
.Poplast {
  width: 100%;
  position: fixed;
  bottom: 0px;
  .btn1 {
    border: none;
    width: 50%;
    height: 42px;
    background: #f1f1f1;
    font-size: 15px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #9b9b9b;
  }
  .btn2 {
    border: none;
    width: 50%;
    height: 42px;
    background: #3974c6;
    font-size: 15px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #ffffff;
  }
}
/deep/ .van-field__label {
  width: 60px;
}
</style>