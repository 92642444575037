<template>
  <div class="findChance">
    <div class="top">
      <div class="tL">
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="option1" />
          <van-dropdown-item v-model="value2" :options="option2" />
        </van-dropdown-menu>
      </div>
      <div class="tR">浏览优先</div>
    </div>
    <div class="banxin main">
      <!-- 企业商机 -->
      <div class="chance">
        <div class="Ctop">
          <div class="left">企业商机</div>
        </div>
        <div class="Cmiddle">
          <div class="content">
            <div class="ctop">
              <h1>品牌/主打名称</h1>
              <p>
                系统开发、公众号运营、pos机支付、企业用品采购等pos机支付、企业用品采购等
              </p>
            </div>
            <div class="cCenter">
              <div class="box">
                <div class="m17"><span>初步预算:</span> <i>5万-6万</i></div>
                <div class="m17">
                  <span>需求时间:</span> <span>广州市地方辅导费</span>
                </div>
                <div class="m17">
                  <span>区域要求:</span> <span>声震地方辅导费</span>
                </div>
                <div class="m17">
                  <img
                    src="../../img/brandInfo/qiyechaxun备份.png"
                    alt=""
                  />&nbsp;<em>深圳市齐声教育科技邮箱公司</em>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="bl"><span>已抢：3人</span></div>
              <button style="margin-top: -4px">抢商机</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value1: 0,
      value2: "a",
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      option2: [
        { text: "默认排序", value: "a" },
        { text: "好评排序", value: "b" },
        { text: "销量排序", value: "c" },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
/deep/ .van-dropdown-item {
  top: 54px !important;
}
.findChance {
  width: 100%;
}
.top {
  height: 47px;
  //   border-bottom: 1px solid #979797;
  .tL {
    float: left;
    width: 66.6%;
    height: 34px;
    font-size: 13px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #3c3c3c;
  }
  .tR {
    float: left;
    width: 33.3%;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #3c3c3c;
    text-align: center;
  }
}
.main {
  // 企业商机
  .chance {
    .Ctop {
      min-height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 21px;
        padding-left: 8px;
        border-left: 2px solid #3974c6;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #000000;
        line-height: 21px;
      }
    }

    .Cmiddle {
      margin-bottom: 10px;
      height: 232px;
      background: #f9f9f9;
      border-radius: 5px;
      overflow: hidden;
      .content {
        margin: 9px auto;
        width: 90%;
        .ctop {
          width: 100%;
          height: 70px;
          h1 {
            // width: 315px;
            height: 21px;
            font-size: 15px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #4a4a4a;
            line-height: 21px;
          }
          p {
            // width: 334px;
            width: 100%;
            height: 48px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #555555;
            line-height: 19px;
          }
        }
        .cCenter {
          // width: 323px;
          width: 100%;
          height: 107px;
          background: #ffffff;
          border-radius: 5px;
          .box {
            // width: 313px;
            width: 96%;
            margin: 8px auto;
            .m17 {
              height: 20px;
              img {
                width: 16px;
                height: 16px;
              }
            }
            span {
              height: 19px;
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
              line-height: 19px;
            }
            i {
              height: 20px;
              font-size: 14px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #e84a10;
              line-height: 20px;
            }
            em {
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #555555;
            }
          }
        }
      }
      .bottom {
        display: flex;
        height: 28px;
        justify-content: space-between;
        align-items: center;
        line-height: 28px;
        margin-top: 8px;
        .bl {
          font-size: 13px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #e84a10;
          line-height: 20px;
        }
        button {
          border: none;
          font-size: 14px;
          color: #fff;
          width: 86px;
          height: 24px;
          line-height: 20px;
          background: #f5a623;
          border-radius: 12px;
        }
      }
    }
  }
}
</style>