<template>
  <div class="pingjiaDetail">
    <div class="main banxin">
      <p>2条企业评价</p>
      <div class="command">
        <div class="Pmcontent">
          <div class="custom">
            <div style="width:40px">客户：</div>
            <div>反倒是卡发电机房大富科技的咖啡对方答复发发呆发放大法</div>
          </div>
          <div class="res">
            <span>&nbsp;&nbsp;&nbsp;回复：</span>
            <i
              >大幅度开发费大富科技地方科技大疯狂打反间谍法地方科技大疯狂打反间谍法</i
            >
          </div>
          <!-- 暂无评价-->
          <!-- <p>暂无评价~</p>
          <div class="inp">
            <input type="text" placeholder="请输入评论内容" />
            <button class="btn2">发布</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.pingjiaDetail {
  width: 100%;
}
.main {
}
p {
  margin:0;
  height: 36px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #515151;
  line-height: 36px;
}
.command{
    width:100%;
  background-color: #f9f9f9;

}
.Pmcontent {
  overflow: hidden;
  width: 95%;
  min-height: 152px;
  margin: 0 auto;
  border-bottom: 1px solid #d8d8d8;
  .custom {
    margin-top: 13px;
    display: flex;
    min-height: 36px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #515151;
    line-height: 20px;
  }
  .res {
    display: flex;
    width: 96%;
    margin: 13px 0 13px 10px;
    min-height: 51px;
    background: #ffffff;
    span {
      width: 79px;
      color: #509dec;
      font-size: 12px;
      margin-top: 4px;
    }
    i {
      height: 33px;
      color: #515151;
      font-size: 12px;
      line-height: 17px;
      margin-top: 4px;
    }
  }
}
</style>