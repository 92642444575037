import request from "@/utils/request";
export class myConsultApi {
    //获取我的咨询
    static myConsult(params) {
        return request({
            url: "/my/consultService",
            method: "get",
            params: params,
        });
    }
    // 取消咨询
    static cancelConsult(params) {
        return request({
            url: "/my/cancelConsultService",
            method: "get",
            params: params,
        });
    }
    // 去付款
    static goPay(params) {
        return request({
            url: "/my/pay",
            method: "get",
            params: params,
        });
    }
}