<template>
  <div class="classify" ref="box" style="background: #fff">
    <div class="top">
      <div
        @click="() => (showSelect = !showSelect)"
        class="select"
        style="margin-top: 16px"
      >
        全部分类 <van-icon name="arrow-down" />
      </div>
      <div
        @click="() => (showAddressSelect = !showAddressSelect)"
        class="select"
        style="margin-top: 16px"
      >
        {{ address == "" ? "企业地址" : address }}
        <van-icon name="arrow-down" />
      </div>
      <div :class="[isActive == 1 ? 'act' : 'topRight']" @click="view">
        浏览优先
      </div>
    </div>
    <div class="chance" v-if="brandInfo.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="Smiddle banxin"
          v-for="(item, index) in brandInfo"
          :key="index"
          @click="skipBrandDetail(item.id)"
        >
          <div class="left">
            <img :src="item.logo" alt="" v-if="item.logo" />
            <span v-else>{{ item.corp_name.slice(0, 1) }}</span>
          </div>
          <div class="right">
            <h1>{{ item.name }}</h1>
            <div class="you" v-if="item.recommend != ''">
              {{ item.recommend }}
            </div>
            <p>{{ item.introduction }}</p>
            <div class="bottom">
              <div class="bleft">
                <img src="" /><span>{{ item.corp_name }}</span>
              </div>
              <!-- <div class="p">100+人最近联系</div> -->
            </div>
          </div>
        </div>
      </van-list>
      <div style="padding-bottom: 50px"></div>
    </div>
    <div v-else>
      <van-empty description="空空如也~" />
    </div>
    <van-popup
      v-model="showSelect"
      :overlay="true"
      style="
        position: absolute;
        top: 167px;
        width: 100%;
        height: auto;
        left: 50% !important;
      "
    >
      <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @finish="onFinish"
      >
      </van-tree-select>
      <div class="btn" @click="finish">完成</div>
    </van-popup>
    <van-popup v-model="showAddressSelect" round position="bottom">
      <van-area
        :area-list="areaList"
        @confirm="selectAddress"
        :columns-num="2"
      />
    </van-popup>
  </div>
</template>
<script>
import { brandApi } from "../../api/brand";
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      isActive: "",
      showAddressSelect: false,
      address: "",
      classify: "",
      province: "",
      city: "",
      district: "",
      areaList,
      fieldValue: "",
      needLength: 0,
      loading: false,
      finished: false,
      total: 0,
      page: 1,
      items: [],
      activeId: "",
      activeIndex: 0,
      showSelect: false,
      brandInfo: [],
      // 分类列表
      classifyList: [],
    };
  },
  created() {
    this.getBrandList();
    this.getClassifyList();
  },
  methods: {
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.showSelect = false;
      // this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    view() {
      if (this.isActive == 1) {
        this.isActive = "";
      } else {
        this.isActive = 1;
      }
      this.getBrandList();
    },
    //选择分类
    finish() {
      this.showSelect = false;
      console.log("activeIndex", this.activeIndex);
      //子类ID
      console.log("activeId", this.activeId);
      this.brandInfo = [];
      // console.log(this.items[this.activeIndex].children[this.activeId].name);
      this.getBrandList();
    },
    //选择地址
    selectAddress(item) {
      this.address = item[0].name + item[1].name;
      this.showAddressSelect = false;
      console.log("选择的地址", this.address);
      this.province = item[0].name;
      this.city = item[1].name;
      this.brandInfo = [];
      this.getBrandList();
    },
    async getBrandList() {
      let { data: res } = await brandApi.brandList({
        page: this.page,
        categoryId: this.activeId,
        province: this.provice,
        city: this.city,
        viewNumOrderBy: this.isActive,
      });
      console.log("品牌", res);
      this.needLength = res.data.length;
      this.brandInfo.push(...res.data); // 将数据放入list中
      console.log("this.brandInfo", this.brandInfo);
      this.loading = false;
      if (this.brandInfo.length >= res.total) {
        this.finished = true; // 结束加载状态
      }
    },
    // 被 @load调用的方法
    onLoad() {
      // 若加载条到了底部
      this.page++; // 分页数加一
      this.getBrandList(); // 调用上面方法,请求数据
    },
    // 获取分类列表
    getClassifyList() {
      brandApi.classifyList().then((res) => {
        console.log("分类", res);
        this.items = res.data;
      });
    },
    // 跳转品牌详情页
    skipBrandDetail(id) {
      console.log("id", id);
      this.$router.push({ path: "/brandInfo/index", query: { id: id } });
    },
  },
};
</script>
<style lang="less" scoped>
.classify {
  position: relative;
  min-height: 100%;
}
.top {
  display: flex;
  justify-content: space-around;
  height: 50px;
  border-bottom: 1px solid #979797;
}
.select {
  float: left;
  margin-top: 14px;
  border: none;
  width: 20.3%;
  text-align: center;
  height: 26px;
  font-size: 13px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #3c3c3c;
  line-height: 19px;
}
.topRight {
  margin-top: 17px;
  float: left;
  height: 18px;
  font-size: 13px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #3c3c3c;
  line-height: 19px;
}
.act {
  margin-top: 17px;
  float: left;
  height: 18px;
  font-size: 13px;
  line-height: 19px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  // background-color: #ccc;
  color: #3974c6;
}
.you {
  position: absolute;
  top: -4px;
  right: -7px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 62px;
  height: 18px;
  background: #f5a623;
  border-radius: 50px 0px 0px 50px;
}
.tui {
  position: absolute;
  top: -4px;
  right: -7px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 59px;
  height: 18px;
  background: #fc5247;
  border-radius: 50px 0px 0px 50px;
}
.Smiddle {
  margin-top: 14px;
  margin-bottom: 14px;
  min-height: 100px;
  overflow: hidden;
  background: #f2f8ff;
  border-radius: 5px;
  .left {
    float: left;
    width: 40px;
    margin: 14px 4px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    span {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      background-color: #3974c6;
      text-align: center;
    }
  }
  .right {
    position: relative;
    float: left;
    // width: 296px;
    width: 84%;
    margin-top: 14px;
    h1 {
      height: 21px;
      font-size: 15px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 21px;
    }
    p {
      // width: 286px;
      width: 100%;
      min-height: 20px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8f8f8f;
      line-height: 19px;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      height: 20px;
      line-height: 20px;
      margin-bottom: 10px;
      .bleft {
        margin-top: -4px;

        height: 20px;
        line-height: 20px;
        span {
          display: inline-block;
          margin-top: -4px;
          height: 20px;
          line-height: 20px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
        }
      }
      .p {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8f8f8f;
      }
    }
  }
}
.btn {
  width: 30%;
  margin-left: 68%;
  margin-bottom: 6px;
  height: 30px;
  border-radius: 10px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  background-color: #0099ff;
}
</style>